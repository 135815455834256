export default class SpecialOffer {
  constructor (props) {
    this.id = props.id
    this.title = props.title
    this.description = props.description
    this.linkId = props.linkId
    this.action = props.action
    this.customStylesheet = props.customStylesheet
    this.created = new Date(props.created)
    this.expires = (props.expires) ? new Date(props.expires) : null
  }
}
