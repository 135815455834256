import React from 'react'

export default class Layout extends React.Component {
  static get contextTypes () {
    return {
      router: React.PropTypes.object.isRequired
    }
  }

  render () {
    return this.props.children
  }
}
