import React, { Component, PropTypes } from 'react'
import IdentityStore from '../stores/identity'
import IdentityActions from '../actions/identity'
import SigninData from '../models/signin-data'
import { Card, CardText, CardActions, CardTitle } from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'

export default class SigninView extends Component {
  constructor () {
    super()
    this.state = {
      identity: IdentityStore.getState(),
      form: new SigninData()
    }

    this.onUpdateIdentity = this.onUpdateIdentity.bind(this)
    this.onChangeUsername = this.onChangeUsername.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  static get contextTypes () {
    return {
      router: PropTypes.object.isRequired
    }
  }

  componentDidMount () {
    IdentityStore.listen(this.onUpdateIdentity)
  }

  componentWillUnmount () {
    IdentityStore.unlisten(this.onUpdateIdentity)
  }

  onUpdateIdentity (identity) {
    this.setState({
      identity: identity,
      form: new SigninData({
        username: this.state.form.username,
        password: this.state.form.password
      })
    })

    if (IdentityStore.isAuthenticated()) {
      setTimeout(() => {
        this.context.router.replace('/')
      }, 1500)
    }
  }

  onChangeUsername (event) {
    this.setState({
      identity: IdentityStore.getState(),
      form: new SigninData({
        username: event.target.value,
        password: this.state.form.password
      })
    })
  }

  onChangePassword (event) {
    this.setState({
      identity: IdentityStore.getState(),
      form: new SigninData({
        username: this.state.form.username,
        password: event.target.value
      })
    })
  }

  onSubmit (event) {
    IdentityActions.signin(this.state.form.username, this.state.form.password)
  }

  render () {
    return (
      <Card>
        <CardTitle title='Sign in' />
        <CardText>
          {
            (() => {
              if (IdentityStore.isAuthenticated()) {
                return <p>Authenticated. You'll be redirected...</p>
              } else {
                return (
                  <div>
                    <TextField
                      value={this.state.form.username}
                      onChange={this.onChangeUsername}
                      disabled={this.state.identity.submitting}
                      hintText='Username'
                      floatingLabelText='Username'
                    />
                    <br />
                    <TextField
                      type='password'
                      value={this.state.form.password}
                      onChange={this.onChangePassword}
                      disabled={this.state.identity.submitting}
                      hintText='Password'
                      floatingLabelText='Password'
                    /><br />
                    {
                      (() => {
                        if (this.state.identity.err) {
                          return <p>{this.state.identity.err.message}</p>
                        } else {
                          return null
                        }
                      })()
                    }
                  </div>
                )
              }
            })()
          }
        </CardText>
        <CardActions>
          {
            (() => {
              if (IdentityStore.isAuthenticated()) {
                return null
              } else {
                return <FlatButton label='Submit' onTouchTap={this.onSubmit} disabled={this.state.identity.submitting} />
              }
            })()
          }
        </CardActions>
      </Card>
    )
  }
}
