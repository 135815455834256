import React from 'react'
import { Card, CardTitle, CardText } from 'material-ui/Card'
import numbro from 'numbro'
import moment from 'moment'

export default class TrackItemView extends React.Component {
  getCTRString (views, clicks) {
    if (views === 0) {
      return 'n/a'
    } else {
      return numbro(clicks / views).format('0.00%')
    }
  }

  getActivityPeriod (beginVal, endVal) {
    const begin = moment(beginVal)
    const end = endVal ? moment(endVal) : moment()
    const now = moment()
    return moment.min(end, now).diff(begin, 'days')
  }

  render () {
    return (
      <Card>
        <CardTitle title='Tracking' />
        <CardText>
          <p>
            <strong>Link: </strong>
            <a href={this.props.linkSource} target='_blank'>{this.props.linkTarget}</a>
          </p>
          <p>
            <strong>Views: </strong>
            <span>{this.props.linkViews}</span>
          </p>
          <p>
            <strong>Clicks: </strong>
            <span>{this.props.linkClicks}</span>
          </p>
          <p>
            <strong>CTR: </strong>
            <span>{this.getCTRString(this.props.linkViews, this.props.linkClicks)}</span>
          </p>
          <p>
            <strong>Activity period: </strong>
            <span>{`${this.getActivityPeriod(this.props.activityBegin, this.props.activityEnd)} day(s)`}</span>
          </p>
        </CardText>
      </Card>
    )
  }
}
