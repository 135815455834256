import alt from '../utils/alt'
import ReleaseNoteActions from '../actions/release-note'
import { List } from 'immutable'

class ReleaseNoteStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: ReleaseNoteActions.UPDATE,
      handleFetch: ReleaseNoteActions.FETCH,
      handleFailed: ReleaseNoteActions.FAILED
    })
  }

  handleUpdate (releaseNotes) {
    this.setState({
      loading: false,
      err: null,
      collection: releaseNotes
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(ReleaseNoteStore, 'ReleaseNoteStore')
