import { Record } from 'immutable'
import NewVersion from './new-version'
import AlteredReleaseNotes from './altered-release-notes'

const AlteredRelease = Record({
  id: null,
  version: new NewVersion(),
  link: '',
  customStylesheet: '',
  notes: new AlteredReleaseNotes()
})

export default AlteredRelease
