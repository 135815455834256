import React from 'react'
import NewReleaseStore from '../../../stores/new-release'
import NewReleaseActions from '../../../actions/new-release'
import NewReleaseFeature from '../../../models/new-release-feature'
import CreateFormNoteFeatureView from './create-form-note-feature-view'
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'

export default class CreateFormNoteFeaturesView extends React.Component {
  constructor () {
    super()

    this.onAddFeature = this.onAddFeature.bind(this)
  }

  onAddFeature (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').update(this.props.noteId, (note) => {
      return note.set('features', note.get('features').push(new NewReleaseFeature()))
    })))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Release features' />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Add' primary onTouchTap={this.onAddFeature} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            {this.props.features.map((feature, ndx) => {
              return <CreateFormNoteFeatureView key={ndx} id={ndx} noteId={this.props.noteId} description={feature.description} disabled={this.props.disabled} />
            })}
            {
              (() => {
                if (this.props.features.size === 0) {
                  return <p>No release features yet</p>
                } else {
                  return null
                }
              })()
            }
          </CardText>
        </Card>
      </div>
    )
  }
}
