import { Record, List } from 'immutable'

const NewSpecialOffer = Record({
  title: '',
  description: '',
  link: '',
  action: '',
  customStylesheet: '',
  expiresOn: null,
  expiresAt: null,
  clauses: new List()
})

export default NewSpecialOffer
