import React, { Component, PropTypes } from 'react'
import IdentityStore from '../stores/identity'
import IdentityActions from '../actions/identity'
import { Card, CardTitle, CardText } from 'material-ui/Card'

export default class SignoutView extends Component {
  constructor () {
    super()
    this.state = IdentityStore.getState()

    this.onUpdateIdentity = this.onUpdateIdentity.bind(this)
  }

  static get contextTypes () {
    return {
      router: PropTypes.object.isRequired
    }
  }

  componentDidMount () {
    IdentityStore.listen(this.onUpdateIdentity)
    IdentityActions.signout()
  }

  componentWillUnmount () {
    IdentityStore.unlisten(this.onUpdateIdentity)
  }

  onUpdateIdentity (identity) {
    this.setState(identity)

    if (!IdentityStore.isAuthenticated()) {
      setTimeout(() => {
        this.context.router.replace('/')
      }, 1500)
    }
  }

  render () {
    return (
      <Card>
        <CardTitle title='Sign out' />
        <CardText>
          {
            (() => {
              if (IdentityStore.isAuthenticated()) {
                return <p>Signing out...</p>
              } else {
                return <p>Signed out. You'll be redirected...</p>
              }
            })()
          }
        </CardText>
      </Card>
    )
  }
}
