import alt from '../utils/alt'
import { List } from 'immutable'
import TrackedLinkStatistic from '../models/tracked-link-statistic'
import apiFetch from '../utils/api-fetch'

function fetchTrackedLinkStatistics () {
  return new Promise((resolve, reject) => {
    let response = null

    apiFetch('/api/tracked-links/statistics/index')
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 200) {
          const trackedLinkStatistics = data.map((props) => {
            return new TrackedLinkStatistic(props)
          })
          resolve(new List(trackedLinkStatistics))
        } else {
          throw new Error(data.error || 'Internal Server Error')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class TrackedLinkStatisticActions {
  update (trackedLinkStatistics) {
    return (dispatch) => {
      dispatch(trackedLinkStatistics)
    }
  }

  fetch () {
    return (dispatch) => {
      dispatch()
      fetchTrackedLinkStatistics()
        .then((trackedLinkStatistics) => {
          this.update(trackedLinkStatistics)
        })
        .catch((err) => {
          this.failed(err)
        })
    }
  }

  failed (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(TrackedLinkStatisticActions)
