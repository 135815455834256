import alt from '../utils/alt'
import ReleaseFeatureActions from '../actions/release-feature'
import { List } from 'immutable'

class ReleaseFeatureStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: ReleaseFeatureActions.UPDATE,
      handleFetch: ReleaseFeatureActions.FETCH,
      handleFailed: ReleaseFeatureActions.FAILED
    })
  }

  handleUpdate (releaseFeatures) {
    this.setState({
      loading: false,
      err: null,
      collection: releaseFeatures
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(ReleaseFeatureStore, 'ReleaseFeatureStore')
