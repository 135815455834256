import alt from '../utils/alt'
import SpecialOfferPreview from '../models/special-offer-preview'
import apiFetch from '../utils/api-fetch'

function fetchSpecialOfferPreview (id) {
  return new Promise((resolve, reject) => {
    let response = null

    apiFetch(`/api/special-offers/${id}/preview`)
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 200) {
          resolve(new SpecialOfferPreview(data))
        } else {
          throw new Error(data.error || 'Internal Server Error')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class SpecialOfferPreviewActions {
  update (specialOfferPreview) {
    return (dispatch) => {
      dispatch(specialOfferPreview)
    }
  }

  fetch (id) {
    return (dispatch) => {
      dispatch()
      fetchSpecialOfferPreview(id)
        .then((specialOfferPreview) => {
          this.update(specialOfferPreview)
        })
        .catch((err) => {
          this.failed(err)
        })
    }
  }

  failed (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(SpecialOfferPreviewActions)
