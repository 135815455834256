import React from 'react'
import AlteredReleaseActions from '../../../actions/altered-release'
import AlteredReleaseStore from '../../../stores/altered-release'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'
import TextField from 'material-ui/TextField'

export default class EditFormNewNoteFeatureView extends React.Component {
  constructor () {
    super()
    this.onChangeDescription = this.onChangeDescription.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  onChangeDescription (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).update(this.props.noteId, (note) => {
      return note.set('features', note.get('features').update(this.props.id, (feature) => {
        return feature.set('description', event.target.value)
      }))
    })))
  }

  onDelete (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).update(this.props.noteId, (note) => {
      return note.set('features', note.get('features').delete(this.props.id))
    })))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release feature ${this.props.id + 1}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Delete' primary onTouchTap={this.onDelete} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <TextField
              value={this.props.description}
              onChange={this.onChangeDescription}
              disabled={this.props.disabled}
              hintText='Description'
              floatingLabelText='Description'
            />
          </CardText>
        </Card>
      </div>
    )
  }
}
