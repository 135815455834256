import React from 'react'
import NewReleaseActions from '../../../actions/new-release'
import NewReleaseStore from '../../../stores/new-release'
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'

export default class CreateFormNoteFeatureView extends React.Component {
  constructor () {
    super()
    this.onChangeDescription = this.onChangeDescription.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  onChangeDescription (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').update(this.props.noteId, (note) => {
      return note.set('features', note.get('features').update(this.props.id, (feature) => {
        return feature.set('description', event.target.value)
      }))
    })))
  }

  onDelete (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').update(this.props.noteId, (note) => {
      return note.set('features', note.get('features').delete(this.props.id))
    })))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release feature ${this.props.id + 1}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Delete' primary onTouchTap={this.onDelete} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <TextField
              value={this.props.description}
              onChange={this.onChangeDescription}
              disabled={this.props.disabled}
              hintText='Description'
              floatingLabelText='Description'
            />
          </CardText>
        </Card>
      </div>
    )
  }
}
