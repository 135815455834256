import React from 'react'
import { Card, CardTitle, CardText } from 'material-ui/Card'
import IconButton from 'material-ui/IconButton'
import FileFileDownload from 'material-ui/svg-icons/file/file-download'
import numbro from 'numbro'
import moment from 'moment'
import apiFetch from '../../../utils/api-fetch'

export default class TrackItemView extends React.Component {
  constructor () {
    super()
    this.onTouchDownloadViewsCsv = this.onTouchDownloadViewsCsv.bind(this)
    this.onTouchDownloadClicksCsv = this.onTouchDownloadClicksCsv.bind(this)
  }

  getCTRString (views, clicks) {
    if (views === 0) {
      return 'n/a'
    } else {
      return numbro(clicks / views).format('0.00%')
    }
  }

  getActivityPeriod (beginVal, endVal) {
    const begin = moment(beginVal)
    const end = endVal ? moment(endVal) : moment()
    const now = moment()
    return moment.min(end, now).diff(begin, 'days')
  }

  onTouchDownloadViewsCsv (event) {
    apiFetch(`/api/tracked-links/statistics/${this.props.linkId}/views`)
      .then((response) => {
        response.blob()
          .then((blob) => {
            const href = window.URL.createObjectURL(blob)
            var link = document.createElement('a')
            link.download = 'views.csv'
            link.href = href
            link.click()
          })
      })
      .catch((err) => {
      })
  }

  onTouchDownloadClicksCsv (event) {
    apiFetch(`/api/tracked-links/statistics/${this.props.linkId}/clicks`)
      .then((response) => {
        response.blob()
          .then((blob) => {
            const href = window.URL.createObjectURL(blob)
            var link = document.createElement('a')
            link.download = 'clicks.csv'
            link.href = href
            link.click()
          })
      })
      .catch((err) => {
      })
  }

  render () {
    return (
      <Card>
        <CardTitle title='Tracking' />
        <CardText>
          <p>
            <strong>Link: </strong>
            <a href={this.props.linkSource} target='_blank'>{this.props.linkTarget}</a>
          </p>
          <p>
            <strong>Views: </strong>
            <span>{this.props.linkViews}</span>
            <IconButton
              onTouchTap={this.onTouchDownloadViewsCsv}
              tooltip="Download CSV"
              style={{ marginLeft: '8px', padding: '0px', width: '16px', height: '16px', verticalAlign: 'middle' }}
              iconStyle={{ width: '16px', height: '16px' }}
            >
              <FileFileDownload />
            </IconButton>
          </p>
          <p>
            <strong>Clicks: </strong>
            <span>{this.props.linkClicks}</span>
            <IconButton
              onTouchTap={this.onTouchDownloadClicksCsv}
              tooltip="Download CSV"
              style={{ marginLeft: '8px', padding: '0px', width: '16px', height: '16px', verticalAlign: 'middle' }}
              iconStyle={{ width: '16px', height: '16px' }}
            >
              <FileFileDownload />
            </IconButton>
          </p>
          <p>
            <strong>CTR: </strong>
            <span>{this.getCTRString(this.props.linkViews, this.props.linkClicks)}</span>
          </p>
          <p>
            <strong>Activity period: </strong>
            <span>{`${this.getActivityPeriod(this.props.activityBegin, this.props.activityEnd)} day(s)`}</span>
          </p>
        </CardText>
      </Card>
    )
  }
}
