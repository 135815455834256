/* global atob */

class MetadataStore {
  constructor () {
    this.metadata = {}
  }

  getMetadata (name) {
    if (!this.metadata[name]) {
      const el = document.querySelector(`script[type="text/x-metadata"][data-name="${name}"]`)
      if (el) {
        this.metadata[name] = atob(el.getAttribute('data-value'))
      } else {
        this.metadata[name] = null
      }
    }

    return this.metadata[name]
  }
}

export default new MetadataStore()
