import { Record, List } from 'immutable'
import NewVersion from './new-version'

const NewRelease = Record({
  version: new NewVersion(),
  link: '',
  customStylesheet: '',
  notes: new List()
})

export default NewRelease
