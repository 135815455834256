import React from 'react'
import AlteredSpecialOfferStore from '../../../stores/altered-special-offer'
import AlteredSpecialOfferActions from '../../../actions/altered-special-offer'
import NewSpecialOfferClause, { NewSpecialOfferClauseLanguageParams } from '../../../models/new-special-offer-clause'
import EditFormNewClauseView from './edit-form-new-clause-view'
import EditFormAlteredClauseView from './edit-form-altered-clause-view'
import EditFormDeletedClauseView from './edit-form-deleted-clause-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'

export default class EditFormClausesView extends React.Component {
  onAddClause (event) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.setIn(['clauses', 'create'], item.getIn(['clauses', 'create']).push(new NewSpecialOfferClause({
      type: 0,
      params: new NewSpecialOfferClauseLanguageParams()
    }))))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Special offer clauses' />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to create' />
              </ToolbarGroup>
              <ToolbarGroup>
                <ToolbarSeparator />
                <RaisedButton label='Add' primary onTouchTap={this.onAddClause} disabled={this.props.disabled} />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.clauses.create.map((clause, ndx) => {
                  return <EditFormNewClauseView key={ndx} id={ndx} languages={this.props.languages} type={clause.type} params={clause.params} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>

            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to update' />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.clauses.update.map((clause, ndx) => {
                  return <EditFormAlteredClauseView key={ndx} id={clause.id} languages={this.props.languages} type={clause.type} params={clause.params} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>

            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to delete' />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.clauses.delete.map((clause, ndx) => {
                  return <EditFormDeletedClauseView key={ndx} id={clause.id} languages={this.props.languages} type={clause.type} params={clause.params} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>
          </CardText>
        </Card>
      </div>
    )
  }
}
