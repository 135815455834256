import React from 'react'
import EditFormDeletedNoteFeatureView from './edit-form-deleted-note-feature-view'

import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'

export default class EditFormDeletedNoteFeaturesView extends React.Component {
  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Release features' />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            {this.props.features.update.map((feature, ndx) => {
              return <EditFormDeletedNoteFeatureView key={feature.id} id={feature.id} noteId={this.props.noteId} description={feature.description} disabled={this.props.disabled} />
            })}
          </CardText>
        </Card>
      </div>
    )
  }
}
