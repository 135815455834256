import React from 'react'
import NewSpecialOfferActions from '../../../actions/new-special-offer'
import NewSpecialOfferStore from '../../../stores/new-special-offer'
import { NewSpecialOfferClauseNullParams, NewSpecialOfferClauseLanguageParams, NewSpecialOfferClauseLicenseParams, NewSpecialOfferClauseUserParams, NewSpecialOfferClauseFrequencyParams } from '../../../models/new-special-offer-clause'
import _ from 'lodash'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'
import TextField from 'material-ui/TextField'

export default class CreateFormClauseView extends React.Component {
  constructor () {
    super()
    this.onChangeType = this.onChangeType.bind(this)
    this.onChangeLanguages = this.onChangeLanguages.bind(this)
    this.onChangeLicenses = this.onChangeLicenses.bind(this)
    this.onChangeRelation = this.onChangeRelation.bind(this)
    this.onChangeBound = this.onChangeBound.bind(this)
    this.onChangeFrequency = this.onChangeFrequency.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  getClauseParams (type) {
    switch (type) {
      case 0: return new NewSpecialOfferClauseLanguageParams()
      case 1: return new NewSpecialOfferClauseLicenseParams()
      case 2: return new NewSpecialOfferClauseUserParams()
      case 3: return new NewSpecialOfferClauseFrequencyParams()
      default: return new NewSpecialOfferClauseNullParams()
    }
  }

  onChangeType (event, ndx, value) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').update(this.props.id, (clause) => {
      const type = parseInt(value, 10)
      return clause.set('type', type).set('params', this.getClauseParams(type))
    })))
  }

  onChangeLanguages (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').update(this.props.id, (clause) => {
      let languages = null
      const val = event.target.value
      if (_.includes(clause.params.languages, val)) {
        languages = _.without(clause.params.languages, val)
      } else {
        languages = _.concat(clause.params.languages, val)
      }
      return clause.setIn(['params', 'languages'], languages)
    })))
  }

  onChangeLicenses (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').update(this.props.id, (clause) => {
      let licenses = null
      const val = parseInt(event.target.value, 10)
      if (_.includes(clause.params.licenses, val)) {
        licenses = _.without(clause.params.licenses, val)
      } else {
        licenses = _.concat(clause.params.licenses, val)
      }
      return clause.setIn(['params', 'licenses'], licenses)
    })))
  }

  onChangeRelation (event, ndx, value) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').update(this.props.id, (clause) => {
      return clause.setIn(['params', 'relation'], value)
    })))
  }

  onChangeBound (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').update(this.props.id, (clause) => {
      return clause.setIn(['params', 'bound'], event.target.value)
    })))
  }

  onChangeFrequency (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').update(this.props.id, (clause) => {
      return clause.setIn(['params', 'frequency'], event.target.value)
    })))
  }

  onDelete (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').delete(this.props.id)))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Special offer clause ${this.props.id + 1}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Delete' primary onTouchTap={this.onDelete} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <SelectField
              hintText='Type'
              floatingLabelText='Type'
              value={this.props.type}
              onChange={this.onChangeType}
              disabled={this.props.disabled}
            >
              <MenuItem key={1} value={0} primaryText='Language constraint' />
              <MenuItem key={2} value={1} primaryText='License constraint' />
              <MenuItem key={3} value={2} primaryText='Users constraint' />
              <MenuItem key={4} value={3} primaryText='Frequency constraint' />
            </SelectField>
            {
              (() => {
                if (this.props.type === 0) {
                  return (
                    <div>
                    {this.props.languages.map((language, ndx) => {
                      return <Checkbox key={ndx} onCheck={this.onChangeLanguages} label={language.title} value={language.title} checked={_.includes(this.props.params.languages, language.title)} disabled={this.props.disabled} />
                    })}
                    </div>
                  )
                } else if (this.props.type === 1) {
                  return (
                    <div>
                      <Checkbox onCheck={this.onChangeLicenses} label='Trial' value={'0'} checked={_.includes(this.props.params.licenses, 0)} disabled={this.props.disabled} />
                      <Checkbox onCheck={this.onChangeLicenses} label='Regular' value={'2'} checked={_.includes(this.props.params.licenses, 2)} disabled={this.props.disabled} />
                      <Checkbox onCheck={this.onChangeLicenses} label='Advanced' value={'3'} checked={_.includes(this.props.params.licenses, 3)} disabled={this.props.disabled} />
                      <Checkbox onCheck={this.onChangeLicenses} label='Ultima' value={'7'} checked={_.includes(this.props.params.licenses, 7)} disabled={this.props.disabled} />
                      <Checkbox onCheck={this.onChangeLicenses} label='Home' value={'8'} checked={_.includes(this.props.params.licenses, 8)} disabled={this.props.disabled} />
                      <Checkbox onCheck={this.onChangeLicenses} label='Educational' value={'16'} checked={_.includes(this.props.params.licenses, 16)} disabled={this.props.disabled} />
                    </div>
                  )
                } else if (this.props.type === 2) {
                  return (
                    <div>
                      <SelectField
                        hintText='Relation'
                        floatingLabelText='Relation'
                        value={this.props.params.relation}
                        onChange={this.onChangeRelation}
                        disabled={this.props.disabled}
                      >
                        <MenuItem key={0} value='<' primaryText='Less than' />
                        <MenuItem key={1} value='<=' primaryText='Less than or equal to' />
                        <MenuItem key={2} value='=' primaryText='Equal to' />
                        <MenuItem key={3} value='>=' primaryText='Equal to or greater than' />
                        <MenuItem key={4} value='>' primaryText='Greater than' />
                      </SelectField>
                      <br />
                      <TextField
                        type='number'
                        value={this.props.params.bound}
                        onChange={this.onChangeBound}
                        disabled={this.props.disabled}
                        hintText='Bound'
                        floatingLabelText='Bound'
                      />
                    </div>
                  )
                } else if (this.props.type === 3) {
                  return (
                    <div>
                      <TextField
                        type='number'
                        value={this.props.params.frequency}
                        onChange={this.onChangeFrequency}
                        disabled={this.props.disabled}
                        hintText='Frequency'
                        floatingLabelText='Frequency'
                      />
                    </div>
                  )
                }
              })()
            }
          </CardText>
        </Card>
      </div>
    )
  }
}
