import alt from '../utils/alt'
import apiFetch from '../utils/api-fetch'

function saveAlteredRelease (release) {
  return new Promise((resolve, reject) => {
    const body = {
      release: {
        version: {
          major: release.version.major,
          minor: release.version.minor,
          patch: release.version.patch
        },
        link: release.link,
        customStylesheet: release.customStylesheet,
        notes: {
          create: release.notes.create.map((note) => {
            return {
              language: note.language,
              title: note.title,
              description: note.description,
              action: note.action,
              features: note.features.map((feature) => {
                return feature.description
              })
            }
          }),
          update: release.notes.update.map((note) => {
            return {
              id: note.id,
              language: note.language,
              title: note.title,
              description: note.description,
              action: note.action,
              features: {
                create: note.features.create.map((feature) => {
                  return feature.description
                }),
                update: note.features.update.map((feature) => {
                  return {
                    id: feature.id,
                    description: feature.description
                  }
                }),
                delete: note.features.delete.map((feature) => {
                  return feature.id
                })
              }
            }
          }),
          delete: release.notes.delete.map((note) => {
            return note.id
          })
        }
      }
    }

    let response = null
    let options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    apiFetch(`/api/releases/${release.id}/update`, options)
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 200) {
          resolve()
        } else {
          let err = 'Internal Server Error'
          if (data.error) {
            err = data.error
            if (data.error.message) {
              err = data.error.message
            }
          }
          throw new Error(err)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class AlteredReleaseActions {
  update (release) {
    return (dispatch) => {
      dispatch(release)
    }
  }

  save (release) {
    return (dispatch) => {
      dispatch(release)
      saveAlteredRelease(release)
        .then(() => {
          this.success()
        })
        .catch((err) => {
          this.failure(err)
        })
    }
  }

  success () {
    return (dispatch) => {
      dispatch()
    }
  }

  failure (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(AlteredReleaseActions)
