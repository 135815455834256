import { Record } from 'immutable'
import { NewSpecialOfferClauseNullParams } from './new-special-offer-clause'

const AlteredSpecialOfferClause = Record({
  id: null,
  type: 0,
  params: new NewSpecialOfferClauseNullParams()
})

export default AlteredSpecialOfferClause
