import alt from '../utils/alt'
import { List } from 'immutable'
import Version from '../models/version'
import apiFetch from '../utils/api-fetch'

function fetchVersions () {
  return new Promise((resolve, reject) => {
    let response = null

    apiFetch('/api/versions/index')
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 200) {
          const versions = data.map((props) => {
            return new Version(props)
          })
          resolve(new List(versions))
        } else {
          throw new Error(data.error || 'Internal Server Error')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class VersionActions {
  update (versions) {
    return (dispatch) => {
      dispatch(versions)
    }
  }

  fetch () {
    return (dispatch) => {
      dispatch()
      fetchVersions()
        .then((versions) => {
          this.update(versions)
        })
        .catch((err) => {
          this.failed(err)
        })
    }
  }

  failed (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(VersionActions)
