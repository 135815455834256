import React from 'react'
import getDataUri from '../../../utils/data-uri'

export default class PreviewItemView extends React.Component {
  render () {
    const style = {
      width: 500,
      height: 250
    }

    return (
      <article>
        <iframe src={getDataUri(this.props.html)} style={style} />
      </article>
    )
  }
}
