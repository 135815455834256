import React from 'react'
import LanguageStore from '../../../stores/language'
import LanguageActions from '../../../actions/language'

import ReleasePreviewStore from '../../../stores/release-preview'
import ReleasePreviewActions from '../../../actions/release-preview'

import PreviewListView from './preview-list-view'

import { Card, CardTitle, CardText } from 'material-ui/Card'

export default class IndexView extends React.Component {
  constructor () {
    super()
    this.state = {
      languages: LanguageStore.getState(),
      previews: ReleasePreviewStore.getState()
    }

    this.onUpdateLanguages = this.onUpdateLanguages.bind(this)
    this.onUpdatePreviews = this.onUpdatePreviews.bind(this)
  }

  componentDidMount () {
    LanguageStore.listen(this.onUpdateLanguages)
    ReleasePreviewStore.listen(this.onUpdatePreviews)

    LanguageActions.fetch()
    ReleasePreviewActions.fetch(this.props.params.releaseId)
  }

  componentWillUnmount () {
    LanguageStore.unlisten(this.onUpdateLanguages)
    ReleasePreviewStore.unlisten(this.onUpdatePreviews)
  }

  onUpdateLanguages (languages) {
    this.setState({
      languages: languages
    })
  }

  onUpdatePreviews (previews) {
    this.setState({
      previews: previews
    })
  }

  isLoading () {
    return this.state.languages.loading || this.state.previews.loading
  }

  isError () {
    return this.state.languages.err || this.state.previews.err
  }

  render () {
    return (
      <Card>
        <CardTitle title='Release preview' />
        <CardText>
        {
          (() => {
            if (this.isLoading()) {
              return <p>Loading</p>
            }
            if (this.isError()) {
              return <p>Failed to fetch releases data</p>
            }
            return <PreviewListView languages={this.state.languages.collection} previews={this.state.previews.collection} />
          })()
        }
        </CardText>
      </Card>
    )
  }
}
