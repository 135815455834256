import alt from '../utils/alt'
import NewSpecialOfferActions from '../actions/new-special-offer'
import NewSpecialOffer from '../models/new-special-offer'

class NewSpecialOfferStore {
  constructor () {
    this.state = {
      submitting: false,
      success: false,
      error: null,
      item: new NewSpecialOffer()
    }

    this.bindListeners({
      handleUpdate: NewSpecialOfferActions.UPDATE,
      handleSave: NewSpecialOfferActions.SAVE,
      handleSuccess: NewSpecialOfferActions.SUCCESS,
      handleFailure: NewSpecialOfferActions.FAILURE
    })
  }

  handleUpdate (specialOffer) {
    this.setState({
      submitting: false,
      success: false,
      error: null,
      item: specialOffer
    })
  }

  handleSave (specialOffer) {
    this.setState({
      submitting: true,
      success: false,
      error: null,
      item: specialOffer
    })
  }

  handleSuccess () {
    this.setState({
      submitting: false,
      success: true,
      error: null,
      item: new NewSpecialOffer()
    })
  }

  handleFailure (err) {
    this.setState({
      submitting: false,
      success: false,
      error: err,
      item: this.state.item
    })
  }
}

export default alt.createStore(NewSpecialOfferStore, 'NewSpecialOfferStore')
