import React from 'react'
import LanguageStore from '../../../stores/language'
import LanguageActions from '../../../actions/language'

import VersionStore from '../../../stores/version'
import VersionActions from '../../../actions/version'

import ReleaseStore from '../../../stores/release'
import ReleaseActions from '../../../actions/release'

import ReleaseNoteStore from '../../../stores/release-note'
import ReleaseNoteActions from '../../../actions/release-note'

import ReleaseFeatureStore from '../../../stores/release-feature'
import ReleaseFeatureActions from '../../../actions/release-feature'

import TrackedLinkStore from '../../../stores/tracked-link'
import TrackedLinkActions from '../../../actions/tracked-link'

import TrackedLinkStatisticStore from '../../../stores/tracked-link-statistic'
import TrackedLinkStatisticActions from '../../../actions/tracked-link-statistic'

import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'

import ListView from './list-view'

export default class IndexView extends React.Component {
  constructor () {
    super()
    this.state = {
      languages: LanguageStore.getState(),
      versions: VersionStore.getState(),
      releases: ReleaseStore.getState(),
      releaseNotes: ReleaseNoteStore.getState(),
      releaseFeatures: ReleaseFeatureStore.getState(),
      trackedLinks: TrackedLinkStore.getState(),
      trackedLinkStatistics: TrackedLinkStatisticStore.getState()
    }

    this.onUpdateLanguages = this.onUpdateLanguages.bind(this)
    this.onUpdateVersions = this.onUpdateVersions.bind(this)
    this.onUpdateReleases = this.onUpdateReleases.bind(this)
    this.onUpdateReleaseNotes = this.onUpdateReleaseNotes.bind(this)
    this.onUpdateReleaseFeatures = this.onUpdateReleaseFeatures.bind(this)
    this.onUpdateTrackedLinks = this.onUpdateTrackedLinks.bind(this)
    this.onUpdateTrackedLinkStatistics = this.onUpdateTrackedLinkStatistics.bind(this)
    this.onTouchTapCreate = this.onTouchTapCreate.bind(this)
  }

  static get contextTypes () {
    return {
      router: React.PropTypes.object.isRequired
    }
  }

  componentDidMount () {
    LanguageStore.listen(this.onUpdateLanguages)
    VersionStore.listen(this.onUpdateVersions)
    ReleaseStore.listen(this.onUpdateReleases)
    ReleaseNoteStore.listen(this.onUpdateReleaseNotes)
    ReleaseFeatureStore.listen(this.onUpdateReleaseFeatures)
    TrackedLinkStore.listen(this.onUpdateTrackedLinks)
    TrackedLinkStatisticStore.listen(this.onUpdateTrackedLinkStatistics)

    LanguageActions.fetch()
    VersionActions.fetch()
    ReleaseActions.fetch()
    ReleaseNoteActions.fetch()
    ReleaseFeatureActions.fetch()
    TrackedLinkActions.fetch()
    TrackedLinkStatisticActions.fetch()
  }

  componentWillUnmount () {
    LanguageStore.unlisten(this.onUpdateLanguages)
    VersionStore.unlisten(this.onUpdateVersions)
    ReleaseStore.unlisten(this.onUpdateReleases)
    ReleaseNoteStore.unlisten(this.onUpdateReleaseNotes)
    ReleaseFeatureStore.unlisten(this.onUpdateReleaseFeatures)
    TrackedLinkStore.unlisten(this.onUpdateTrackedLinks)
    TrackedLinkStatisticStore.unlisten(this.onUpdateTrackedLinkStatistics)
  }

  onUpdateLanguages (languages) {
    this.setState({
      languages: languages
    })
  }

  onUpdateVersions (versions) {
    this.setState({
      versions: versions
    })
  }

  onUpdateReleases (releases) {
    this.setState({
      releases: releases
    })
  }

  onUpdateReleaseNotes (releaseNotes) {
    this.setState({
      releaseNotes: releaseNotes
    })
  }

  onUpdateReleaseFeatures (releaseFeatures) {
    this.setState({
      releaseFeatures: releaseFeatures
    })
  }

  onUpdateTrackedLinks (trackedLinks) {
    this.setState({
      trackedLinks: trackedLinks
    })
  }

  onUpdateTrackedLinkStatistics (trackedLinkStatistics) {
    this.setState({
      trackedLinkStatistics: trackedLinkStatistics
    })
  }

  isLoading () {
    return this.state.languages.loading || this.state.versions.loading || this.state.releases.loading || this.state.releaseNotes.loading || this.state.releaseFeatures.loading || this.state.trackedLinks.loading || this.state.trackedLinkStatistics.loading
  }

  isError () {
    return this.state.languages.err || this.state.versions.err || this.state.releases.err || this.state.releaseNotes.err || this.state.releaseFeatures.err || this.state.trackedLinks.err || this.state.trackedLinkStatistics.err
  }

  getReleaseData () {
    return this.state.releases.collection.map((release) => {
      const version = this.state.versions.collection.find((version) => {
        return version.id === release.versionId
      })

      const link = this.state.trackedLinks.collection.find((link) => {
        return link.id === release.linkId
      })

      const linkStatistic = this.state.trackedLinkStatistics.collection.find((linkStatistic) => {
        return linkStatistic.linkId === release.linkId
      })

      const notes = this.state.releaseNotes.collection.filter((releaseNote) => {
        return releaseNote.releaseId === release.id
      }).map((releaseNote) => {
        const language = this.state.languages.collection.find((language) => {
          return language.id === releaseNote.languageId
        })

        const features = this.state.releaseFeatures.collection.filter((releaseFeature) => {
          return releaseFeature.releaseNoteId === releaseNote.id
        })

        return {
          id: releaseNote.id,
          language: language,
          title: releaseNote.title,
          description: releaseNote.description,
          features: features,
          action: releaseNote.action,
          created: releaseNote.created
        }
      })

      return {
        id: release.id,
        version: version,
        link: link,
        linkStatistic: linkStatistic,
        notes: notes,
        created: release.created
      }
    }).sort((a, b) => {
      const aMajor = a.version.major
      const bMajor = b.version.major

      if (aMajor < bMajor) {
        return 1
      } else if (aMajor > bMajor) {
        return -1
      } else {
        const aMinor = a.version.minor
        const bMinor = b.version.minor

        if (aMinor < bMinor) {
          return 1
        } else if (aMinor > bMinor) {
          return -1
        } else {
          const aPatch = a.version.patch
          const bPatch = b.version.patch

          if (aPatch < bPatch) {
            return 1
          } else if (aPatch > bPatch) {
            return -1
          } else {
            return 0
          }
        }
      }
    })
  }

  onTouchTapCreate () {
    this.context.router.push('/drexplain/releases/new')
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Dr.Explain → Releases' />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Create' primary onTouchTap={this.onTouchTapCreate} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
          {
            (() => {
              if (this.isLoading()) {
                return <p>Loading</p>
              }
              if (this.isError()) {
                return <p>Failed to fetch releases data</p>
              }
              return <ListView releases={this.getReleaseData()} />
            })()
          }
          </CardText>
        </Card>
      </div>
    )
  }
}
