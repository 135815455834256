import React from 'react'
import AlteredReleaseStore from '../../../stores/altered-release'
import AlteredReleaseActions from '../../../actions/altered-release'
import NewReleaseFeature from '../../../models/new-release-feature'
import EditFormAlteredNoteNewFeatureView from './edit-form-altered-note-new-feature-view'
import EditFormAlteredNoteAlteredFeatureView from './edit-form-altered-note-altered-feature-view'
import EditFormAlteredNoteDeletedFeatureView from './edit-form-altered-note-deleted-feature-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'

export default class EditFormAlteredNoteFeaturesView extends React.Component {
  constructor () {
    super()

    this.onAddFeature = this.onAddFeature.bind(this)
  }

  onAddFeature (event) {
    const item = AlteredReleaseStore.getState().item
    const notes = item.getIn(['notes', 'update'])
    const ndx = notes.findIndex((note) => {
      return note.id === this.props.noteId
    })

    AlteredReleaseActions.update(item.setIn(['notes', 'update'], notes.update(ndx, (note) => {
      return note.setIn(['features', 'create'], note.getIn(['features', 'create']).push(new NewReleaseFeature()))
    })))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Release features' />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to create' />
              </ToolbarGroup>
              <ToolbarGroup>
                <ToolbarSeparator />
                <RaisedButton label='Add' primary onTouchTap={this.onAddFeature} disabled={this.props.disabled} />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.features.create.map((feature, ndx) => {
                  return <EditFormAlteredNoteNewFeatureView key={ndx} id={ndx} noteId={this.props.noteId} description={feature.description} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>

            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to update' />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.features.update.map((feature, ndx) => {
                  return <EditFormAlteredNoteAlteredFeatureView key={ndx} id={feature.id} noteId={this.props.noteId} description={feature.description} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>

            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to delete' />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.features.delete.map((feature, ndx) => {
                  return <EditFormAlteredNoteDeletedFeatureView key={ndx} id={feature.id} noteId={this.props.noteId} description={feature.description} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>
          </CardText>
        </Card>

      </div>
    )
  }
}
