import alt from '../utils/alt'
import NewReleaseActions from '../actions/new-release'
import NewRelease from '../models/new-release'

class NewReleaseStore {
  constructor () {
    this.state = {
      submitting: false,
      success: false,
      error: null,
      item: new NewRelease()
    }

    this.bindListeners({
      handleUpdate: NewReleaseActions.UPDATE,
      handleSave: NewReleaseActions.SAVE,
      handleSuccess: NewReleaseActions.SUCCESS,
      handleFailure: NewReleaseActions.FAILURE
    })
  }

  handleUpdate (release) {
    this.setState({
      submitting: false,
      success: false,
      error: null,
      item: release
    })
  }

  handleSave (release) {
    this.setState({
      submitting: true,
      success: false,
      error: null,
      item: release
    })
  }

  handleSuccess () {
    this.setState({
      submitting: false,
      success: true,
      error: null,
      item: new NewRelease()
    })
  }

  handleFailure (err) {
    this.setState({
      submitting: false,
      success: false,
      error: err,
      item: this.state.item
    })
  }
}

export default alt.createStore(NewReleaseStore, 'NewReleaseStore')
