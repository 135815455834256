exports['cs-CZ'] = require('./cs-CZ.js');
exports['da-DK'] = require('./da-DK.js');
exports['de-CH'] = require('./de-CH.js');
exports['de-DE'] = require('./de-DE.js');
exports['en-AU'] = require('./en-AU.js');
exports['en-GB'] = require('./en-GB.js');
exports['en-NZ'] = require('./en-NZ.js');
exports['en-ZA'] = require('./en-ZA.js');
exports['es-AR'] = require('./es-AR.js');
exports['es-ES'] = require('./es-ES.js');
exports['et-EE'] = require('./et-EE.js');
exports['fa-IR'] = require('./fa-IR.js');
exports['fi-FI'] = require('./fi-FI.js');
exports['fil-PH'] = require('./fil-PH.js');
exports['fr-CA'] = require('./fr-CA.js');
exports['fr-CH'] = require('./fr-CH.js');
exports['fr-FR'] = require('./fr-FR.js');
exports['he-IL'] = require('./he-IL.js');
exports['hu-HU'] = require('./hu-HU.js');
exports['it-IT'] = require('./it-IT.js');
exports['ja-JP'] = require('./ja-JP.js');
exports['ko-KR'] = require('./ko-KR.js');
exports['lv-LV'] = require('./lv-LV.js');
exports['nb-NO'] = require('./nb-NO.js');
exports['nl-BE'] = require('./nl-BE.js');
exports['nl-NL'] = require('./nl-NL.js');
exports['pl-PL'] = require('./pl-PL.js');
exports['pt-BR'] = require('./pt-BR.js');
exports['pt-PT'] = require('./pt-PT.js');
exports['ru-RU'] = require('./ru-RU.js');
exports['ru-UA'] = require('./ru-UA.js');
exports['sk-SK'] = require('./sk-SK.js');
exports['sv-SE'] = require('./sv-SE.js');
exports['th-TH'] = require('./th-TH.js');
exports['tr-TR'] = require('./tr-TR.js');
exports['uk-UA'] = require('./uk-UA.js');
exports['zh-CN'] = require('./zh-CN.js');
exports['zh-TW'] = require('./zh-TW.js');