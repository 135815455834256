import { Record, List } from 'immutable'

const NewReleaseNote = Record({
  language: 'english',
  title: '',
  description: '',
  action: '',
  features: new List()
})

export default NewReleaseNote
