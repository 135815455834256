import React from 'react'
import AlteredReleaseStore from '../../../stores/altered-release'
import AlteredReleaseActions from '../../../actions/altered-release'
import NewReleaseFeature from '../../../models/new-release-feature'
import EditFormNewNoteFeatureView from './edit-form-new-note-feature-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'

export default class EditFormNewNoteFeaturesView extends React.Component {
  constructor () {
    super()

    this.onAddFeature = this.onAddFeature.bind(this)
  }

  onAddFeature (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).update(this.props.noteId, (note) => {
      return note.set('features', note.get('features').push(new NewReleaseFeature()))
    })))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Release features' />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Add' primary onTouchTap={this.onAddFeature} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            {this.props.features.map((feature, ndx) => {
              return <EditFormNewNoteFeatureView key={ndx} id={ndx} noteId={this.props.noteId} description={feature.description} disabled={this.props.disabled} />
            })}
          </CardText>
        </Card>
      </div>
    )
  }
}
