import React from 'react'
import ItemView from './item-view'

export default class ListView extends React.Component {
  render () {
    return (
      <section>
        {this.props.specialOffers.map((specialOffer) => {
          return <ItemView key={specialOffer.id} id={specialOffer.id} title={specialOffer.title} description={specialOffer.description} link={specialOffer.link} linkStatistic={specialOffer.linkStatistic} action={specialOffer.action} created={specialOffer.created} expires={specialOffer.expires} clauses={specialOffer.clauses} />
        })}
      </section>
    )
  }
}
