import React, { Component } from 'react'
import { Link } from 'react-router'
import { Card, CardText } from 'material-ui/Card'
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'

export default class HomeView extends Component {
  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Dr.Explain' />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <ul>
              <li><Link to='/drexplain/releases/index'>Releases</Link></li>
              <li><Link to='/drexplain/special-offers/index'>Special offers</Link></li>
            </ul>
          </CardText>
        </Card>
      </div>
    )
  }
}
