import React, { Component } from 'react'
import { Card, CardText } from 'material-ui/Card'
import IdentityStore from '../stores/identity'
import { Link } from 'react-router'
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'

export default class HomeView extends Component {
  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Home' />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <ul>
              {
                (() => {
                  if (IdentityStore.isAuthenticated()) {
                    return <li><Link to='/drexplain'>Dr.Explain</Link></li>
                  } else {
                    return <li><Link to='/signin'>Sign in</Link></li>
                  }
                })()
              }
            </ul>
          </CardText>
        </Card>
      </div>
    )
  }
}
