import React from 'react'
import AlteredReleaseActions from '../../../actions/altered-release'
import AlteredReleaseStore from '../../../stores/altered-release'
import EditFormNewNoteFeaturesView from './edit-form-new-note-features-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'

export default class EditFormNewNoteView extends React.Component {
  constructor () {
    super()
    this.onChangeLanguage = this.onChangeLanguage.bind(this)
    this.onChangeTitle = this.onChangeTitle.bind(this)
    this.onChangeDescription = this.onChangeDescription.bind(this)
    this.onChangeAction = this.onChangeAction.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  onChangeLanguage (event, ndx, value) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).update(this.props.id, (note) => {
      return note.set('language', value)
    })))
  }

  onChangeTitle (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).update(this.props.id, (note) => {
      return note.set('title', event.target.value)
    })))
  }

  onChangeDescription (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).update(this.props.id, (note) => {
      return note.set('description', event.target.value)
    })))
  }

  onChangeAction (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).update(this.props.id, (note) => {
      return note.set('action', event.target.value)
    })))
  }

  onDelete (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).delete(this.props.id)))
  }

  render () {
    const customDescriptionTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release note ${this.props.id + 1}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Delete' primary onTouchTap={this.onDelete} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <SelectField
              value={this.props.language}
              onChange={this.onChangeLanguage}
              disabled={this.props.disabled}
              hintText='Language'
              floatingLabelText='Language'
            >
              {this.props.languages.map((language) => {
                return <MenuItem key={language.id} value={language.title} primaryText={language.title} />
              })}
            </SelectField>
            <br />
            <TextField
              value={this.props.title}
              onChange={this.onChangeTitle}
              disabled={this.props.disabled}
              hintText='Title'
              floatingLabelText='Title'
            />
            <br />
            <TextField
              value={this.props.description}
              onChange={this.onChangeDescription}
              disabled={this.props.disabled}
              hintText='Description'
              floatingLabelText='Description'
              multiLine
              style={customDescriptionTextareaStyle}
            />
            <br />
            <TextField
              value={this.props.action}
              onChange={this.onChangeAction}
              disabled={this.props.disabled}
              hintText='Action'
              floatingLabelText='Action'
            />
            <br />
            <EditFormNewNoteFeaturesView noteId={this.props.id} features={this.props.features} disabled={this.props.disabled} />
          </CardText>
        </Card>
      </div>
    )
  }
}
