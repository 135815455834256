import { Record } from 'immutable'
import AlteredSpecialOfferClauses from './altered-special-offer-clauses'

const AlteredSpecialOffer = Record({
  id: null,
  title: '',
  description: '',
  link: '',
  action: '',
  customStylesheet: '',
  expiresOn: null,
  expiresAt: null,
  clauses: new AlteredSpecialOfferClauses()
})

export default AlteredSpecialOffer
