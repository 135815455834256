import React from 'react'
import getDataUri from '../../../utils/data-uri'
import { Card, CardTitle, CardText } from 'material-ui/Card'

export default class PreviewItemView extends React.Component {
  render () {
    const style = {
      width: 500,
      height: 250
    }

    return (
      <Card>
        <CardTitle title={this.props.language} />
        <CardText>
          <iframe src={getDataUri(this.props.html)} style={style} />
        </CardText>
      </Card>
    )
  }
}
