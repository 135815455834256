import React from 'react'
import LanguageStore from '../../../stores/language'
import LanguageActions from '../../../actions/language'
import CreateFormView from './create-form-view'

import { Card, CardTitle, CardText, CardActions } from 'material-ui/Card'
import NewReleaseStore from '../../../stores/new-release'
import NewReleaseActions from '../../../actions/new-release'
import RaisedButton from 'material-ui/RaisedButton'

export default class CreateView extends React.Component {
  constructor () {
    super()
    this.state = {
      languages: LanguageStore.getState()
    }

    this.onUpdateLanguages = this.onUpdateLanguages.bind(this)
  }

  componentDidMount () {
    LanguageStore.listen(this.onUpdateLanguages)

    LanguageActions.fetch()
  }

  componentWillUnmount () {
    LanguageStore.unlisten(this.onUpdateLanguages)
  }

  onUpdateLanguages (languages) {
    this.setState({
      languages: languages
    })
  }

  isLoading () {
    return this.state.languages.loading
  }

  isError () {
    return this.state.languages.err
  }

  onSave () {
    const state = NewReleaseStore.getState()
    NewReleaseActions.save(state.item)
  }

  isSubmitting () {
    return NewReleaseStore.getState().submitting
  }

  render () {
    return (
      <Card>
        <CardTitle title='Create release' />
        <CardText>
          {
            (() => {
              if (this.isLoading()) {
                return <p>Loading</p>
              }
              if (this.isError()) {
                return <p>Failed to fetch releases data</p>
              }
              return <CreateFormView languages={this.state.languages.collection} />
            })()
          }
        </CardText>
        <CardActions>
          <RaisedButton label='Save' primary onTouchTap={this.onSave} disabled={this.isSubmitting()} />
        </CardActions>
      </Card>
    )
  }
}
