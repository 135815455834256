import alt from '../utils/alt'
import SpecialOfferActions from '../actions/special-offer'
import { List } from 'immutable'

class SpecialOfferStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: SpecialOfferActions.UPDATE,
      handleFetch: SpecialOfferActions.FETCH,
      handleFailed: SpecialOfferActions.FAILED
    })
  }

  handleUpdate (specialOffers) {
    this.setState({
      loading: false,
      err: null,
      collection: specialOffers
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(SpecialOfferStore, 'SpecialOfferStore')
