import React from 'react'
import AlteredReleaseActions from '../../../actions/altered-release'
import AlteredReleaseStore from '../../../stores/altered-release'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'

export default class EditFormAlteredNoteAlteredFeatureView extends React.Component {
  constructor () {
    super()
    this.onChangeDescription = this.onChangeDescription.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  onChangeDescription (event) {
    const item = AlteredReleaseStore.getState().item
    const notes = item.getIn(['notes', 'update'])
    const ndx = notes.findIndex((note) => {
      return note.id === this.props.noteId
    })

    AlteredReleaseActions.update(item.setIn(['notes', 'update'], item.getIn(['notes', 'update']).update(ndx, (note) => {
      const features = note.getIn(['features', 'update'])
      const ndx2 = features.findIndex((feature) => {
        return feature.id === this.props.id
      })

      return note.setIn(['features', 'update'], features.update(ndx2, (feature) => {
        return feature.set('description', event.target.value)
      }))
    })))
  }

  onDelete (event) {
    const item = AlteredReleaseStore.getState().item
    const notes = item.getIn(['notes', 'update'])
    const ndx = notes.findIndex((note) => {
      return note.id === this.props.noteId
    })
    AlteredReleaseActions.update(item.setIn(['notes', 'update'], item.getIn(['notes', 'update']).update(ndx, (note) => {
      const features = note.getIn(['features', 'update'])
      const ndx2 = features.findIndex((feature) => {
        return feature.id === this.props.id
      })
      const feature = features.get(ndx2)
      return note
        .setIn(['features', 'update'], features.delete(ndx2))
        .setIn(['features', 'delete'], note.getIn(['features', 'delete']).push(feature))
    })))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release feature #${this.props.id}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Delete' primary onTouchTap={this.onDelete} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <TextField
              value={this.props.description}
              onChange={this.onChangeDescription}
              disabled={this.props.disabled}
              hintText='Description'
              floatingLabelText='Description'
            />
          </CardText>
        </Card>
      </div>
    )
  }
}
