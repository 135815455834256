import alt from '../utils/alt'
import AlteredReleaseActions from '../actions/altered-release'
import AlteredRelease from '../models/altered-release'

class AlteredReleaseStore {
  constructor () {
    this.state = {
      submitting: false,
      success: false,
      error: null,
      item: new AlteredRelease()
    }

    this.bindListeners({
      handleUpdate: AlteredReleaseActions.UPDATE,
      handleSave: AlteredReleaseActions.SAVE,
      handleSuccess: AlteredReleaseActions.SUCCESS,
      handleFailure: AlteredReleaseActions.FAILURE
    })
  }

  handleUpdate (release) {
    this.setState({
      submitting: false,
      success: false,
      error: null,
      item: release
    })
  }

  handleSave (release) {
    this.setState({
      submitting: true,
      success: false,
      error: null,
      item: release
    })
  }

  handleSuccess () {
    this.setState({
      submitting: false,
      success: true,
      error: null,
      item: new AlteredRelease()
    })
  }

  handleFailure (err) {
    this.setState({
      submitting: false,
      success: false,
      error: err,
      item: this.state.item
    })
  }
}

export default alt.createStore(AlteredReleaseStore, 'AlteredReleaseStore')
