import alt from '../utils/alt'
import TrackedLinkActions from '../actions/tracked-link'
import { List } from 'immutable'

class TrackedLinkStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: TrackedLinkActions.UPDATE,
      handleFetch: TrackedLinkActions.FETCH,
      handleFailed: TrackedLinkActions.FAILED
    })
  }

  handleUpdate (trackedLinks) {
    this.setState({
      loading: false,
      err: null,
      collection: trackedLinks
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(TrackedLinkStore, 'TrackedLinkStore')
