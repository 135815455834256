import alt from '../utils/alt'
import TrackedLinkStatisticActions from '../actions/tracked-link-statistic'
import { List } from 'immutable'

class TrackedLinkStatisticStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: TrackedLinkStatisticActions.UPDATE,
      handleFetch: TrackedLinkStatisticActions.FETCH,
      handleFailed: TrackedLinkStatisticActions.FAILED
    })
  }

  handleUpdate (trackedLinkStatistics) {
    this.setState({
      loading: false,
      err: null,
      collection: trackedLinkStatistics
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(TrackedLinkStatisticStore, 'TrackedLinkStatisticStore')
