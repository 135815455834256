import React from 'react'
import LanguageStore from '../../../stores/language'
import LanguageActions from '../../../actions/language'

import SpecialOfferStore from '../../../stores/special-offer'
import SpecialOfferActions from '../../../actions/special-offer'

import SpecialOfferClauseStore from '../../../stores/special-offer-clause'
import SpecialOfferClauseActions from '../../../actions/special-offer-clause'

import TrackedLinkStore from '../../../stores/tracked-link'
import TrackedLinkActions from '../../../actions/tracked-link'

import TrackedLinkStatisticStore from '../../../stores/tracked-link-statistic'
import TrackedLinkStatisticActions from '../../../actions/tracked-link-statistic'

import ListView from './list-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'

export default class IndexView extends React.Component {
  constructor () {
    super()
    this.state = {
      languages: LanguageStore.getState(),
      specialOffers: SpecialOfferStore.getState(),
      specialOfferClauses: SpecialOfferClauseStore.getState(),
      trackedLinks: TrackedLinkStore.getState(),
      trackedLinkStatistics: TrackedLinkStatisticStore.getState()
    }

    this.onUpdateLanguages = this.onUpdateLanguages.bind(this)
    this.onUpdateSpecialOffers = this.onUpdateSpecialOffers.bind(this)
    this.onUpdateSpecialOfferClauses = this.onUpdateSpecialOfferClauses.bind(this)
    this.onUpdateTrackedLinks = this.onUpdateTrackedLinks.bind(this)
    this.onUpdateTrackedLinkStatistics = this.onUpdateTrackedLinkStatistics.bind(this)
    this.onTouchTapCreate = this.onTouchTapCreate.bind(this)
  }

  static get contextTypes () {
    return {
      router: React.PropTypes.object.isRequired
    }
  }

  componentDidMount () {
    LanguageStore.listen(this.onUpdateLanguages)
    SpecialOfferStore.listen(this.onUpdateSpecialOffers)
    SpecialOfferClauseStore.listen(this.onUpdateSpecialOfferClauses)
    TrackedLinkStore.listen(this.onUpdateTrackedLinks)
    TrackedLinkStatisticStore.listen(this.onUpdateTrackedLinkStatistics)

    LanguageActions.fetch()
    SpecialOfferActions.fetch()
    SpecialOfferClauseActions.fetch()
    TrackedLinkActions.fetch()
    TrackedLinkStatisticActions.fetch()
  }

  componentWillUnmount () {
    LanguageStore.unlisten(this.onUpdateLanguages)
    SpecialOfferStore.unlisten(this.onUpdateSpecialOffers)
    SpecialOfferClauseStore.unlisten(this.onUpdateSpecialOfferClauses)
    TrackedLinkStore.unlisten(this.onUpdateTrackedLinks)
    TrackedLinkStatisticStore.unlisten(this.onUpdateTrackedLinkStatistics)
  }

  onUpdateLanguages (languages) {
    this.setState({
      languages: languages
    })
  }

  onUpdateSpecialOffers (specialOffers) {
    this.setState({
      specialOffers: specialOffers
    })
  }

  onUpdateSpecialOfferClauses (specialOfferClauses) {
    this.setState({
      specialOfferClauses: specialOfferClauses
    })
  }

  onUpdateTrackedLinks (trackedLinks) {
    this.setState({
      trackedLinks: trackedLinks
    })
  }

  onUpdateTrackedLinkStatistics (trackedLinkStatistics) {
    this.setState({
      trackedLinkStatistics: trackedLinkStatistics
    })
  }

  isLoading () {
    return this.state.languages.loading || this.state.specialOffers.loading || this.state.specialOfferClauses.loading || this.state.trackedLinks.loading || this.state.trackedLinkStatistics.loading
  }

  isError () {
    return this.state.languages.err || this.state.specialOffers.err || this.state.specialOfferClauses.err || this.state.trackedLinks.err || this.state.trackedLinkStatistics.err
  }

  getSpecialOfferData () {
    return this.state.specialOffers.collection.map((specialOffer) => {
      const link = this.state.trackedLinks.collection.find((link) => {
        return link.id === specialOffer.linkId
      })

      const linkStatistic = this.state.trackedLinkStatistics.collection.find((linkStatistic) => {
        return linkStatistic.linkId === specialOffer.linkId
      })

      const clauses = this.state.specialOfferClauses.collection.filter((specialOfferClause) => {
        return specialOfferClause.specialOfferId === specialOffer.id
      }).map((specialOfferClause) => {
        return {
          id: specialOfferClause.id,
          type: specialOfferClause.type,
          params: ((type, params) => {
            switch (type) {
              case 0: return {
                languages: params.languages.map((languageId) => {
                  const language = this.state.languages.collection.find((language) => {
                    return language.id === languageId
                  })

                  return language.title
                })
              }
              case 1: return {
                licenses: params.licenses.map((licenseId) => {
                  switch (licenseId) {
                    case 0: return 'Trial'
                    case 2: return 'Regular'
                    case 3: return 'Advanced'
                    case 7: return 'Ultima'
                    case 8: return 'Home'
                    case 16: return 'Educational'
                    default: return 'Unknown'
                  }
                })
              }
              case 2: return {
                bound: params.bound,
                relation: params.relation
              }
              case 3: return {
                frequency: params.frequency
              }
              default: return {
              }
            }
          })(specialOfferClause.type, specialOfferClause.params)
        }
      })

      return {
        id: specialOffer.id,
        title: specialOffer.title,
        description: specialOffer.description,
        link: link,
        linkStatistic: linkStatistic,
        action: specialOffer.action,
        created: specialOffer.created,
        expires: specialOffer.expires,
        clauses: clauses
      }
    }).sort((a, b) => {
      const aVal = a.created.valueOf()
      const bVal = b.created.valueOf()
      if (aVal < bVal) {
        return 1
      } else if (aVal > bVal) {
        return -1
      } else {
        return 0
      }
    })
  }

  onTouchTapCreate () {
    this.context.router.push('/drexplain/special-offers/new')
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Dr.Explain → Special offers' />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Create' primary onTouchTap={this.onTouchTapCreate} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            {
              (() => {
                if (this.isLoading()) {
                  return <p>Loading</p>
                }
                if (this.isError()) {
                  return <p>Failed to fetch special offers data</p>
                }
                return <ListView specialOffers={this.getSpecialOfferData()} />
              })()
            }
          </CardText>
        </Card>
      </div>
    )
  }
}
