import React from 'react'
import NewReleaseActions from '../../../actions/new-release'
import NewReleaseStore from '../../../stores/new-release'
import CreateFormNoteFeaturesView from './create-form-note-features-view'
import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { Card, CardText } from 'material-ui/Card'

export default class CreateFormNoteView extends React.Component {
  constructor () {
    super()
    this.onChangeLanguage = this.onChangeLanguage.bind(this)
    this.onChangeTitle = this.onChangeTitle.bind(this)
    this.onChangeDescription = this.onChangeDescription.bind(this)
    this.onChangeAction = this.onChangeAction.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  onChangeLanguage (event, ndx, value) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').update(this.props.id, (note) => {
      return note.set('language', value)
    })))
  }

  onChangeTitle (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').update(this.props.id, (note) => {
      return note.set('title', event.target.value)
    })))
  }

  onChangeDescription (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').update(this.props.id, (note) => {
      return note.set('description', event.target.value)
    })))
  }

  onChangeAction (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').update(this.props.id, (note) => {
      return note.set('action', event.target.value)
    })))
  }

  onDelete (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').delete(this.props.id)))
  }

  render () {
    const customDescriptionTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release note ${this.props.id + 1}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Delete' primary onTouchTap={this.onDelete} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <SelectField
              value={this.props.language}
              onChange={this.onChangeLanguage}
              disabled={this.props.disabled}
              hintText='Language'
              floatingLabelText='Language'
            >
              {this.props.languages.map((language) => {
                return <MenuItem key={language.id} value={language.title} primaryText={language.title} />
              })}
            </SelectField>
            <br />
            <TextField
              value={this.props.title}
              onChange={this.onChangeTitle}
              disabled={this.props.disabled}
              hintText='Title'
              floatingLabelText='Title'
            />
            <br />
            <TextField
              value={this.props.description}
              onChange={this.onChangeDescription}
              disabled={this.props.disabled}
              hintText='Description'
              floatingLabelText='Description'
              multiLine
              style={customDescriptionTextareaStyle}
            />
            <br />
            <TextField
              value={this.props.action}
              onChange={this.onChangeAction}
              disabled={this.props.disabled}
              hintText='Action'
              floatingLabelText='Action'
            />
            <br />
            <CreateFormNoteFeaturesView noteId={this.props.id} features={this.props.features} disabled={this.props.disabled} />
          </CardText>
        </Card>
      </div>
    )
  }
}
