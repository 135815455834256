import React from 'react'
import AlteredReleaseStore from '../../../stores/altered-release'
import AlteredReleaseActions from '../../../actions/altered-release'
import AlteredRelease from '../../../models/altered-release'
import AlteredReleaseNotes from '../../../models/altered-release-notes'
import AlteredReleaseNote from '../../../models/altered-release-note'
import AlteredReleaseFeatures from '../../../models/altered-release-features'
import AlteredReleaseFeature from '../../../models/altered-release-feature'
import NewVersion from '../../../models/new-version'
import EditFormNotesView from './edit-form-notes-view'
import { List } from 'immutable'
import TextField from 'material-ui/TextField'

export default class EditFormView extends React.Component {
  constructor () {
    super()
    this.state = AlteredReleaseStore.getState()
    this.onUpdate = this.onUpdate.bind(this)
  }

  onUpdate (release) {
    this.setState(release)
  }

  componentDidMount () {
    AlteredReleaseStore.listen(this.onUpdate)
    setTimeout(() => {
      AlteredReleaseActions.update(new AlteredRelease({
        id: this.props.release.id,
        version: new NewVersion({
          major: this.props.release.version.major,
          minor: this.props.release.version.minor,
          patch: this.props.release.version.patch
        }),
        link: this.props.release.link,
        customStylesheet: this.props.release.customStylesheet,
        notes: new AlteredReleaseNotes({
          create: new List(),
          update: new List(this.props.release.notes.map((note) => {
            return new AlteredReleaseNote({
              id: note.id,
              language: note.language.title,
              title: note.title,
              description: note.description,
              action: note.action,
              features: new AlteredReleaseFeatures({
                create: new List(),
                update: new List(note.features.map((feature) => {
                  return new AlteredReleaseFeature({
                    id: feature.id,
                    description: feature.description
                  })
                })),
                delete: new List()
              })
            })
          })),
          delete: new List()
        })
      }))
    }, 500)
  }

  componentWillUnmount () {
    AlteredReleaseStore.unlisten(this.onUpdate)
  }

  onChangeVersionMajor (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['version', 'major'], event.target.value))
  }

  onChangeVersionMinor (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['version', 'minor'], event.target.value))
  }

  onChangeVersionPatch (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['version', 'patch'], event.target.value))
  }

  onChangeLink (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.set('link', event.target.value))
  }

  onChangeCustomStylesheet (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.set('customStylesheet', event.target.value))
  }

  render () {
    const versionInputStyle = {
      width: '6em'
    }

    const linkInputStyle = {
      minWidth: '40em'
    }

    const customStylesheetTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    return (
      <div>
        <h3>Version</h3>
        <TextField
          type='number'
          value={this.state.item.version.major}
          onChange={this.onChangeVersionMajor}
          disabled={this.state.submitting}
          hintText='Major'
          floatingLabelText='Major'
          style={versionInputStyle}
        />
        <br />

        <TextField
          type='number'
          value={this.state.item.version.minor}
          onChange={this.onChangeVersionMinor}
          disabled={this.state.submitting}
          hintText='Minor'
          floatingLabelText='Minor'
          style={versionInputStyle}
        />
        <br />

        <TextField
          type='number'
          value={this.state.item.version.patch}
          onChange={this.onChangeVersionPatch}
          disabled={this.state.submitting}
          hintText='Patch'
          floatingLabelText='Patch'
          style={versionInputStyle}
        />
        <br />

        <h3>Link</h3>
        <TextField
          type='url'
          value={this.state.item.link}
          onChange={this.onChangeLink}
          disabled={this.state.submitting}
          hintText='URL'
          floatingLabelText='URL'
          style={linkInputStyle}
        />
        <br />
        <h3>Extra styling</h3>
        <TextField
          value={this.state.item.customStylesheet}
          onChange={this.onChangeCustomStylesheet}
          disabled={this.state.submitting}
          hintText='Custom stylesheet'
          floatingLabelText='Custom stylesheet'
          multiLine
          style={customStylesheetTextareaStyle}
        />
        <br />
        <EditFormNotesView languages={this.props.languages} notes={this.state.item.notes} disabled={this.state.submitting} />
        {
          (() => {
            if (this.state.submitting) {
              return <p>Submitting</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.error) {
              return <p>{this.state.error.message}</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.success) {
              return <p>Saved!</p>
            } else {
              return null
            }
          })()
        }
      </div>
    )
  }
}
