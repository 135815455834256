import React from 'react'
import PreviewItemView from './preview-item-view'

export default class PreviewListView extends React.Component {
  render () {
    return (
      <div>
        {this.props.previews.map((preview, ndx) => {
          const language = this.props.languages.find((language) => {
            return language.id === preview.languageId
          })
          return <PreviewItemView key={ndx} language={language.title} html={preview.previewHtml} />
        })}
      </div>
    )
  }
}
