import React from 'react'
import { Card, CardTitle, CardText } from 'material-ui/Card'

export default class NoteItemView extends React.Component {
  render () {
    return (
      <Card>
        <CardTitle title={`Release notes in ${this.props.language.title}`} />
        <CardText>
          <p><strong>Title:</strong> {this.props.title}</p>
          <p><strong>Description:</strong> {this.props.description}</p>
          <p><strong>Action:</strong> {this.props.action}</p>
          <h3>Features</h3>
          <ul>
          {this.props.features.map((feature) => {
            return <li key={feature.id}>{feature.description}</li>
          })}
          </ul>
        </CardText>
      </Card>
    )
  }
}
