import alt from '../utils/alt'
import { List } from 'immutable'
import ReleasePreview from '../models/release-preview'
import apiFetch from '../utils/api-fetch'

function fetchReleasePreview (id) {
  return new Promise((resolve, reject) => {
    let response = null

    apiFetch(`/api/releases/${id}/preview`)
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 200) {
          resolve(new List(data.map((props) => {
            return new ReleasePreview(props)
          })))
        } else {
          throw new Error(data.error || 'Internal Server Error')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class ReleasePreviewActions {
  update (releasePreviews) {
    return (dispatch) => {
      dispatch(releasePreviews)
    }
  }

  fetch (id) {
    return (dispatch) => {
      dispatch()
      fetchReleasePreview(id)
        .then((releasePreviews) => {
          this.update(releasePreviews)
        })
        .catch((err) => {
          this.failed(err)
        })
    }
  }

  failed (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(ReleasePreviewActions)
