import alt from '../utils/alt'
import SpecialOfferPreviewActions from '../actions/special-offer-preview'

class SpecialOfferPreviewStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      item: {
        previewHtml: ''
      }
    }

    this.bindListeners({
      handleUpdate: SpecialOfferPreviewActions.UPDATE,
      handleFetch: SpecialOfferPreviewActions.FETCH,
      handleFailed: SpecialOfferPreviewActions.FAILED
    })
  }

  handleUpdate (specialOfferPreview) {
    this.setState({
      loading: false,
      err: null,
      item: specialOfferPreview
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      item: {
        previewHtml: ''
      }
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      item: {
        previewHtml: ''
      }
    })
  }
}

export default alt.createStore(SpecialOfferPreviewStore, 'SpecialOfferPreviewStore')
