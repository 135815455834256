import alt from '../utils/alt'
import ReleaseActions from '../actions/release'
import { List } from 'immutable'

class ReleaseStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: ReleaseActions.UPDATE,
      handleFetch: ReleaseActions.FETCH,
      handleFailed: ReleaseActions.FAILED
    })
  }

  handleUpdate (releases) {
    this.setState({
      loading: false,
      err: null,
      collection: releases
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(ReleaseStore, 'ReleaseStore')
