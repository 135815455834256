import React from 'react'
import ReactDOM from 'react-dom'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import MainRouter from './components/main-router'
import injectTapEventPlugin from 'react-tap-event-plugin'

function onDocumentReady (callback) {
  if (document.readyState !== 'loading') {
    callback()
  } else {
    document.addEventListener('DOMContentLoaded', callback)
  }
}

const App = () => (
  <MuiThemeProvider muiTheme={getMuiTheme()}>
    <MainRouter />
  </MuiThemeProvider>
)

onDocumentReady(function () {
  injectTapEventPlugin()

  ReactDOM.render(
    <App />,
    document.getElementById('app')
  )
})
