import React from 'react'
import AlteredReleaseStore from '../../../stores/altered-release'
import AlteredReleaseActions from '../../../actions/altered-release'
import NewReleaseNote from '../../../models/new-release-note'
import EditFormNewNoteView from './edit-form-new-note-view'
import EditFormAlteredNoteView from './edit-form-altered-note-view'
import EditFormDeletedNoteView from './edit-form-deleted-note-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'

export default class EditFormNotesView extends React.Component {
  onAddNote (event) {
    const item = AlteredReleaseStore.getState().item
    AlteredReleaseActions.update(item.setIn(['notes', 'create'], item.getIn(['notes', 'create']).push(new NewReleaseNote())))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Release notes' />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to create' />
              </ToolbarGroup>
              <ToolbarGroup>
                <ToolbarSeparator />
                <RaisedButton label='Add' primary onTouchTap={this.onAddNote} disabled={this.props.disabled} />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.notes.create.map((note, ndx) => {
                  return <EditFormNewNoteView key={ndx} id={ndx} languages={this.props.languages} language={note.language} title={note.title} description={note.description} action={note.action} features={note.features} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>

            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to update' />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.notes.update.map((note, ndx) => {
                  return <EditFormAlteredNoteView key={ndx} id={note.id} languages={this.props.languages} language={note.language} title={note.title} description={note.description} action={note.action} features={note.features} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>

            <Toolbar>
              <ToolbarGroup>
                <ToolbarTitle text='Records to delete' />
              </ToolbarGroup>
            </Toolbar>
            <Card>
              <CardText>
                {this.props.notes.delete.map((note, ndx) => {
                  return <EditFormDeletedNoteView key={ndx} id={note.id} languages={this.props.languages} language={note.language} title={note.title} description={note.description} action={note.action} features={note.features} disabled={this.props.disabled} />
                })}
              </CardText>
            </Card>
          </CardText>
        </Card>
      </div>
    )
  }
}
