function deserializeParams (type, params) {
  switch (type) {
    case 0: return {
      languages: params.languages
    }
    case 1: return {
      licenses: params.licenses
    }
    case 2: return {
      bound: params.bound,
      relation: params.relation
    }
    case 3: return {
      frequency: params.frequency
    }
    default: return {
    }
  }
}

export default class SpecialOfferClause {
  constructor (props) {
    this.id = props.id
    this.specialOfferId = props.specialOfferId
    this.type = props.type
    this.params = deserializeParams(props.type, props.params)
    this.created = new Date(props.created)
  }
}
