import alt from '../utils/alt'
import SpecialOfferClauseActions from '../actions/special-offer-clause'
import { List } from 'immutable'

class SpecialOfferClauseStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: SpecialOfferClauseActions.UPDATE,
      handleFetch: SpecialOfferClauseActions.FETCH,
      handleFailed: SpecialOfferClauseActions.FAILED
    })
  }

  handleUpdate (specialOfferClauses) {
    this.setState({
      loading: false,
      err: null,
      collection: specialOfferClauses
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(SpecialOfferClauseStore, 'SpecialOfferClauseStore')
