import React, { Component } from 'react'

export default class NotFoundView extends Component {
  render () {
    return (
      <div>
        <h1>Not Found</h1>
      </div>
    )
  }
}
