/* global fetch */
import 'whatwg-fetch'
import IdentityStore from '../stores/identity'
import metadataStore from './metadata-store'

export default function apiFetch (urlPart, options = {}) {
  const url = `${metadataStore.getMetadata('endpoint.drexplain-updates')}${urlPart}`
  if (!options.headers) {
    options.headers = {}
  }
  options.headers['Authorization'] = `Bearer ${IdentityStore.getToken()}`

  return fetch(url, options)
}
