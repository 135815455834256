import React from 'react'
import LanguageStore from '../../../stores/language'
import LanguageActions from '../../../actions/language'

import VersionStore from '../../../stores/version'
import VersionActions from '../../../actions/version'

import ReleaseStore from '../../../stores/release'
import ReleaseActions from '../../../actions/release'

import ReleaseNoteStore from '../../../stores/release-note'
import ReleaseNoteActions from '../../../actions/release-note'

import ReleaseFeatureStore from '../../../stores/release-feature'
import ReleaseFeatureActions from '../../../actions/release-feature'

import TrackedLinkStore from '../../../stores/tracked-link'
import TrackedLinkActions from '../../../actions/tracked-link'

import EditFormView from './edit-form-view'
import { Card, CardText, CardTitle, CardActions } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'
import AlteredReleaseStore from '../../../stores/altered-release'
import AlteredReleaseActions from '../../../actions/altered-release'

export default class EditView extends React.Component {
  constructor () {
    super()
    this.state = {
      languages: LanguageStore.getState(),
      versions: VersionStore.getState(),
      releases: ReleaseStore.getState(),
      releaseNotes: ReleaseNoteStore.getState(),
      releaseFeatures: ReleaseFeatureStore.getState(),
      trackedLinks: TrackedLinkStore.getState()
    }

    this.onUpdateLanguages = this.onUpdateLanguages.bind(this)
    this.onUpdateVersions = this.onUpdateVersions.bind(this)
    this.onUpdateReleases = this.onUpdateReleases.bind(this)
    this.onUpdateReleaseNotes = this.onUpdateReleaseNotes.bind(this)
    this.onUpdateReleaseFeatures = this.onUpdateReleaseFeatures.bind(this)
    this.onUpdateTrackedLinks = this.onUpdateTrackedLinks.bind(this)
  }

  componentDidMount () {
    LanguageStore.listen(this.onUpdateLanguages)
    VersionStore.listen(this.onUpdateVersions)
    ReleaseStore.listen(this.onUpdateReleases)
    ReleaseNoteStore.listen(this.onUpdateReleaseNotes)
    ReleaseFeatureStore.listen(this.onUpdateReleaseFeatures)
    TrackedLinkStore.listen(this.onUpdateTrackedLinks)

    LanguageActions.fetch()
    VersionActions.fetch()
    ReleaseActions.fetch()
    ReleaseNoteActions.fetch()
    ReleaseFeatureActions.fetch()
    TrackedLinkActions.fetch()
  }

  componentWillUnmount () {
    LanguageStore.unlisten(this.onUpdateLanguages)
    VersionStore.unlisten(this.onUpdateVersions)
    ReleaseStore.unlisten(this.onUpdateReleases)
    ReleaseNoteStore.unlisten(this.onUpdateReleaseNotes)
    ReleaseFeatureStore.unlisten(this.onUpdateReleaseFeatures)
    TrackedLinkStore.unlisten(this.onUpdateTrackedLinks)
  }

  onUpdateLanguages (languages) {
    this.setState({
      languages: languages
    })
  }

  onUpdateVersions (versions) {
    this.setState({
      versions: versions
    })
  }

  onUpdateReleases (releases) {
    this.setState({
      releases: releases
    })
  }

  onUpdateReleaseNotes (releaseNotes) {
    this.setState({
      releaseNotes: releaseNotes
    })
  }

  onUpdateReleaseFeatures (releaseFeatures) {
    this.setState({
      releaseFeatures: releaseFeatures
    })
  }

  onUpdateTrackedLinks (trackedLinks) {
    this.setState({
      trackedLinks: trackedLinks
    })
  }

  isLoading () {
    return this.state.languages.loading || this.state.versions.loading || this.state.releases.loading || this.state.releaseNotes.loading || this.state.releaseFeatures.loading || this.state.trackedLinks.loading
  }

  isError () {
    return this.state.languages.err || this.state.versions.err || this.state.releases.err || this.state.releaseNotes.err || this.state.releaseFeatures.err || this.state.trackedLinks.err
  }

  getReleaseData (releaseId) {
    if (this.isLoading()) {
      return null
    }

    const release = this.state.releases.collection.find((release) => {
      return release.id === releaseId
    })

    const version = this.state.versions.collection.find((version) => {
      return version.id === release.versionId
    })

    const link = this.state.trackedLinks.collection.find((link) => {
      return link.id === release.linkId
    })

    const notes = this.state.releaseNotes.collection.filter((releaseNote) => {
      return releaseNote.releaseId === release.id
    }).map((releaseNote) => {
      const language = this.state.languages.collection.find((language) => {
        return language.id === releaseNote.languageId
      })

      const features = this.state.releaseFeatures.collection.filter((releaseFeature) => {
        return releaseFeature.releaseNoteId === releaseNote.id
      })

      return {
        id: releaseNote.id,
        language: language,
        title: releaseNote.title,
        description: releaseNote.description,
        features: features,
        action: releaseNote.action,
        created: releaseNote.created
      }
    })

    return {
      id: release.id,
      version: version,
      link: link.target,
      customStylesheet: release.customStylesheet,
      notes: notes,
      created: release.created
    }
  }

  onSave () {
    const state = AlteredReleaseStore.getState()
    AlteredReleaseActions.save(state.item)
  }

  isSubmitting () {
    return AlteredReleaseStore.getState().submitting
  }

  render () {
    return (
      <Card>
        <CardTitle title='Edit release' />
        <CardText>
          {
            (() => {
              if (this.isLoading()) {
                return <p>Loading</p>
              }
              if (this.isError()) {
                return <p>Failed to fetch releases data</p>
              }
              return <EditFormView languages={this.state.languages.collection} release={this.getReleaseData(parseInt(this.props.params.releaseId, 10))} />
            })()
          }
        </CardText>
        <CardActions>
          <RaisedButton label='Save' primary onTouchTap={this.onSave} disabled={this.isSubmitting()} />
        </CardActions>
      </Card>
    )
  }
}
