import alt from '../utils/alt'
import ReleasePreviewActions from '../actions/release-preview'
import { List } from 'immutable'

class ReleasePreviewStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: ReleasePreviewActions.UPDATE,
      handleFetch: ReleasePreviewActions.FETCH,
      handleFailed: ReleasePreviewActions.FAILED
    })
  }

  handleUpdate (releasePreviews) {
    this.setState({
      loading: false,
      err: null,
      collection: releasePreviews
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(ReleasePreviewStore, 'ReleasePreviewStore')
