export default class ReleaseNote {
  constructor (props) {
    this.id = props.id
    this.releaseId = props.releaseId
    this.languageId = props.languageId
    this.title = props.title
    this.description = props.description
    this.action = props.action
    this.created = new Date(props.created)
  }
}
