import alt from '../utils/alt'
import 'whatwg-fetch'
/* global fetch, localStorage */

function submitSignin (username, password) {
  return new Promise((resolve, reject) => {
    const body = {
      username: username,
      password: password
    }

    let response = null

    fetch('/api/signin', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then((_response) => {
      response = _response
      return _response.json()
    })
    .then((data) => {
      if (response.status === 200) {
        resolve(data.token)
      } else {
        let err = 'Internal Server Error'
        if (data.error) {
          err = data.error
          if (data.error.message) {
            err = data.error.message
          }
        }
        throw new Error(err)
      }
    })
    .catch((err) => {
      reject(err)
    })
  })
}

class IdentityActions {
  update (token) {
    return (dispatch) => {
      dispatch(token)
    }
  }

  signin (username, password) {
    return (dispatch) => {
      dispatch()
      submitSignin(username, password)
        .then((token) => {
          localStorage.token = token
          this.update(token)
        })
        .catch((err) => {
          this.failed(err)
        })
    }
  }

  signout () {
    return (dispatch) => {
      dispatch()
      setTimeout(() => {
        delete localStorage.token
        this.update(null)
      })
    }
  }

  failed (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(IdentityActions)
