import alt from '../utils/alt'
import apiFetch from '../utils/api-fetch'
import moment from 'moment'

function getExpires (expiresOn, expiresAt) {
  if (!expiresOn || !expiresAt) {
    return null
  }

  const dateVal = moment(expiresOn)
  const timeVal = moment(expiresAt)

  return moment().set({
    year: dateVal.get('year'),
    month: dateVal.get('month'),
    date: dateVal.get('date'),
    hour: timeVal.get('hour'),
    minute: timeVal.get('minute'),
    second: timeVal.get('second'),
    millisecond: timeVal.get('millisecond')
  }).toISOString()
}

function saveNewSpecialOffer (specialOffer) {
  return new Promise((resolve, reject) => {
    const body = {
      specialOffer: {
        title: specialOffer.title,
        description: specialOffer.description,
        link: specialOffer.link,
        action: specialOffer.action,
        customStylesheet: specialOffer.customStylesheet,
        expires: getExpires(specialOffer.expiresOn, specialOffer.expiresAt),
        clauses: specialOffer.clauses.map((clause) => {
          return {
            type: clause.type,
            params: ((type, params) => {
              switch (type) {
                case 0: return { languages: params.languages }
                case 1: return { licenses: params.licenses }
                case 2: return { relation: params.relation, bound: params.bound }
                case 3: return { frequency: params.frequency }
                default: return {}
              }
            })(clause.type, clause.params)
          }
        })
      }
    }

    let response = null
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    apiFetch('/api/special-offers/create', options)
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 201) {
          resolve()
        } else {
          let err = 'Internal Server Error'
          if (data.error) {
            err = data.error
            if (data.error.message) {
              err = data.error.message
            }
          }
          throw new Error(err)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class NewSpecialOfferActions {
  update (specialOffer) {
    return (dispatch) => {
      dispatch(specialOffer)
    }
  }

  save (specialOffer) {
    return (dispatch) => {
      dispatch(specialOffer)
      saveNewSpecialOffer(specialOffer)
        .then(() => {
          this.success()
        })
        .catch((err) => {
          this.failure(err)
        })
    }
  }

  success () {
    return (dispatch) => {
      dispatch()
    }
  }

  failure (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(NewSpecialOfferActions)
