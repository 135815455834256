import alt from '../utils/alt'
import { List } from 'immutable'
import ReleaseNote from '../models/release-note'
import apiFetch from '../utils/api-fetch'

function fetchReleaseNotes () {
  return new Promise((resolve, reject) => {
    let response = null

    apiFetch('/api/release-notes/index')
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 200) {
          const releaseNotes = data.map((props) => {
            return new ReleaseNote(props)
          })
          resolve(new List(releaseNotes))
        } else {
          throw new Error(data.error || 'Internal Server Error')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class ReleaseNoteActions {
  update (releaseNotes) {
    return (dispatch) => {
      dispatch(releaseNotes)
    }
  }

  fetch () {
    return (dispatch) => {
      dispatch()
      fetchReleaseNotes()
        .then((releaseNotes) => {
          this.update(releaseNotes)
        })
        .catch((err) => {
          this.failed(err)
        })
    }
  }

  failed (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(ReleaseNoteActions)
