import React from 'react'

import SpecialOfferPreviewStore from '../../../stores/special-offer-preview'
import SpecialOfferPreviewActions from '../../../actions/special-offer-preview'

import PreviewItemView from './preview-item-view'
import { Card, CardText, CardTitle } from 'material-ui/Card'

export default class IndexView extends React.Component {
  constructor () {
    super()
    this.state = SpecialOfferPreviewStore.getState()

    this.onUpdatePreview = this.onUpdatePreview.bind(this)
  }

  componentDidMount () {
    SpecialOfferPreviewStore.listen(this.onUpdatePreview)
    SpecialOfferPreviewActions.fetch(this.props.params.specialOfferId)
  }

  componentWillUnmount () {
    SpecialOfferPreviewStore.unlisten(this.onUpdatePreview)
  }

  onUpdatePreview (preview) {
    this.setState(preview)
  }

  isLoading () {
    return this.state.loading
  }

  isError () {
    return this.state.err
  }

  render () {
    return (
      <Card>
        <CardTitle title='Special offer preview' />
        <CardText>
          {
            (() => {
              if (this.isLoading()) {
                return <p>Loading</p>
              }
              if (this.isError()) {
                return <p>Failed to fetch special offer data</p>
              }
              return <PreviewItemView html={this.state.item.previewHtml} />
            })()
          }
        </CardText>
      </Card>
    )
  }
}
