import React from 'react'
import AlteredReleaseActions from '../../../actions/altered-release'
import AlteredReleaseStore from '../../../stores/altered-release'
import EditFormDeletedNoteFeaturesView from './edit-form-deleted-note-features-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'
import MenuItem from 'material-ui/MenuItem'

export default class EditFormDeletedNoteView extends React.Component {
  constructor () {
    super()
    this.onCancelDeletion = this.onCancelDeletion.bind(this)
  }

  onCancelDeletion (event) {
    const item = AlteredReleaseStore.getState().item
    const notes = item.getIn(['notes', 'delete'])
    const ndx = notes.findIndex((note) => {
      return note.id === this.props.id
    })
    const note = item.getIn(['notes', 'delete']).get(ndx)
    AlteredReleaseActions.update(item
      .setIn(['notes', 'delete'], notes.delete(ndx))
      .setIn(['notes', 'update'], item.getIn(['notes', 'update']).push(note))
    )
  }

  render () {
    const customDescriptionTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release note #${this.props.id}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Cancel deletion' primary onTouchTap={this.onCancelDeletion} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <SelectField
              value={this.props.language}
              disabled
              hintText='Language'
              floatingLabelText='Language'
            >
              {this.props.languages.map((language) => {
                return <MenuItem key={language.id} value={language.title} primaryText={language.title} />
              })}
            </SelectField>
            <br />
            <TextField
              value={this.props.title}
              disabled
              hintText='Title'
              floatingLabelText='Title'
            />
            <br />
            <TextField
              value={this.props.description}
              disabled
              hintText='Description'
              floatingLabelText='Description'
              multiLine
              style={customDescriptionTextareaStyle}
            />
            <br />
            <TextField
              value={this.props.action}
              disabled
              hintText='Action'
              floatingLabelText='Action'
            />
            <br />
            <EditFormDeletedNoteFeaturesView noteId={this.props.id} features={this.props.features} disabled={this.props.disabled} />
          </CardText>
        </Card>
      </div>
    )
  }
}
