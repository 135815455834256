import React from 'react'
import NewSpecialOfferStore from '../../../stores/new-special-offer'
import NewSpecialOfferActions from '../../../actions/new-special-offer'
import NewSpecialOfferClause, { NewSpecialOfferClauseLanguageParams } from '../../../models/new-special-offer-clause'
import CreateFormClauseView from './create-form-clause-view'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'

export default class CreateFormClausesView extends React.Component {
  onAddClause (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('clauses', item.get('clauses').push(new NewSpecialOfferClause({
      type: 0,
      params: new NewSpecialOfferClauseLanguageParams()
    }))))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Special offer clauses' />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Add' primary onTouchTap={this.onAddClause} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            {this.props.clauses.map((clause, ndx) => {
              return <CreateFormClauseView key={ndx} id={ndx} languages={this.props.languages} type={clause.type} params={clause.params} disabled={this.props.disabled} />
            })}
          </CardText>
        </Card>
      </div>
    )
  }
}
