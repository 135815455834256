import React from 'react'
import { List } from 'immutable'
import AlteredSpecialOfferStore from '../../../stores/altered-special-offer'
import AlteredSpecialOfferActions from '../../../actions/altered-special-offer'
import AlteredSpecialOffer from '../../../models/altered-special-offer'
import AlteredSpecialOfferClauses from '../../../models/altered-special-offer-clauses'
import AlteredSpecialOfferClause from '../../../models/altered-special-offer-clause'
import { NewSpecialOfferClauseNullParams, NewSpecialOfferClauseLanguageParams, NewSpecialOfferClauseLicenseParams, NewSpecialOfferClauseUserParams, NewSpecialOfferClauseFrequencyParams } from '../../../models/new-special-offer-clause'
import EditFormClausesView from './edit-form-clauses-view'

import TextField from 'material-ui/TextField'
import DatePicker from 'material-ui/DatePicker'
import TimePicker from 'material-ui/TimePicker'

export default class EditFormView extends React.Component {
  constructor () {
    super()
    this.state = AlteredSpecialOfferStore.getState()
    this.onUpdate = this.onUpdate.bind(this)
  }

  onUpdate (specialOffer) {
    this.setState(specialOffer)
  }

  componentDidMount () {
    AlteredSpecialOfferStore.listen(this.onUpdate)
    setTimeout(() => {
      AlteredSpecialOfferActions.update(new AlteredSpecialOffer({
        id: this.props.specialOffer.id,
        title: this.props.specialOffer.title,
        description: this.props.specialOffer.description,
        link: this.props.specialOffer.link,
        action: this.props.specialOffer.action,
        customStylesheet: this.props.specialOffer.customStylesheet,
        expiresOn: this.props.specialOffer.expiresOn,
        expiresAt: this.props.specialOffer.expiresAt,
        clauses: new AlteredSpecialOfferClauses({
          create: new List(),
          update: new List(this.props.specialOffer.clauses.map((specialOfferClause) => {
            return new AlteredSpecialOfferClause({
              id: specialOfferClause.id,
              type: specialOfferClause.type,
              params: ((type, params) => {
                switch (type) {
                  case 0: return NewSpecialOfferClauseLanguageParams({
                    languages: params.languages
                  })
                  case 1: return NewSpecialOfferClauseLicenseParams({
                    licenses: params.licenses
                  })
                  case 2: return NewSpecialOfferClauseUserParams({
                    relation: params.relation,
                    bound: params.bound
                  })
                  case 3: return NewSpecialOfferClauseFrequencyParams({
                    frequency: params.frequency
                  })
                  default: return NewSpecialOfferClauseNullParams()
                }
              })(specialOfferClause.type, specialOfferClause.params)
            })
          })),
          delete: new List()
        })
      }))
    }, 500)
  }

  componentWillUnmount () {
    AlteredSpecialOfferStore.unlisten(this.onUpdate)
  }

  onChangeTitle (event) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.set('title', event.target.value))
  }

  onChangeDescription (event) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.set('description', event.target.value))
  }

  onChangeAction (event) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.set('action', event.target.value))
  }

  onChangeExpiresOn (event, value) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.set('expiresOn', value))
  }

  onChangeExpiresAt (event, value) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.set('expiresAt', value))
  }

  onChangeLink (event) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.set('link', event.target.value))
  }

  onChangeCustomStylesheet (event) {
    const item = AlteredSpecialOfferStore.getState().item
    AlteredSpecialOfferActions.update(item.set('customStylesheet', event.target.value))
  }

  render () {
    const customDescriptionTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    const customStylesheetTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    const linkInputStyle = {
      minWidth: '40em'
    }

    return (
      <div>
        <TextField
          value={this.state.item.title}
          onChange={this.onChangeTitle}
          disabled={this.state.submitting}
          hintText='Title'
          floatingLabelText='Title'
        />
        <br />
        <TextField
          value={this.state.item.description}
          onChange={this.onChangeDescription}
          disabled={this.state.submitting}
          hintText='Description'
          floatingLabelText='Description'
          multiLine
          style={customDescriptionTextareaStyle}
        />
        <br />
        <TextField
          value={this.state.item.action}
          onChange={this.onChangeAction}
          disabled={this.state.submitting}
          hintText='Action'
          floatingLabelText='Action'
        />
        <br />
        <TextField
          value={this.state.item.customStylesheet}
          onChange={this.onChangeCustomStylesheet}
          disabled={this.state.submitting}
          hintText='Custom stylesheet'
          floatingLabelText='Custom stylesheet'
          multiLine
          style={customStylesheetTextareaStyle}
        />
        <br />
        <h3>Expires</h3>
        <DatePicker
          hintText='Expires on'
          floatingLabelText='Expires on'
          value={this.state.item.expiresOn}
          onChange={this.onChangeExpiresOn}
          disabled={this.state.submitting}
        />
        <TimePicker
          format='24hr'
          hintText='Expires at'
          floatingLabelText='Expires at'
          value={this.state.item.expiresAt}
          onChange={this.onChangeExpiresAt}
          disabled={this.state.submitting}
        />
        <br />
        <h3>Link</h3>
        <TextField
          type='url'
          value={this.state.item.link}
          onChange={this.onChangeLink}
          disabled={this.state.submitting}
          hintText='URL'
          floatingLabelText='URL'
          style={linkInputStyle}
        />

        <EditFormClausesView languages={this.props.languages} clauses={this.state.item.clauses} disabled={this.state.submitting} />
        {
          (() => {
            if (this.state.submitting) {
              return <p>Submitting</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.error) {
              return <p>{this.state.error.message}</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.success) {
              return <p>Saved!</p>
            } else {
              return null
            }
          })()
        }
      </div>
    )
  }
}
