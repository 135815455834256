import alt from '../utils/alt'
import IdentityActions from '../actions/identity'
/* global localStorage, atob */

class IdentityStore {
  constructor () {
    this.state = {
      submitting: false,
      err: null,
      token: IdentityStore.hasToken() ? IdentityStore.parseToken(IdentityStore.getToken()) : null
    }

    this.bindListeners({
      handleUpdate: IdentityActions.UPDATE,
      handleFailed: IdentityActions.FAILED
    })
  }

  handleUpdate (token) {
    this.setState({
      submitting: false,
      err: null,
      token: token ? IdentityStore.parseToken(token) : null
    })
  }

  handleSignin () {
    this.setState({
      submitting: true,
      err: null,
      token: null
    })
  }

  handleFailed (err) {
    this.setState({
      submitting: false,
      err: err,
      token: null
    })
  }

  static hasToken () {
    return !!localStorage.token
  }

  static getToken () {
    return localStorage.token
  }

  static parseToken (token) {
    const [ header, payload, signature ] = token.split('.')
    return JSON.parse(atob(payload))
  }

  static isAuthenticated () {
    const state = this.getState()
    if (state.token) {
      return state.token.exp && (state.token.exp * 1000) > Date.now()
    } else {
      return false
    }
  }
}

export default alt.createStore(IdentityStore, 'IdentityStore')
