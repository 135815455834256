import React from 'react'

export default class ClauseItemView extends React.Component {
  getClauseTitle () {
    switch (this.props.type) {
      case 0: return 'Language constraint'
      case 1: return 'License constraint'
      case 2: return 'Number of users constraint'
      case 3: return 'Frequency constraint'
      default: return 'Unknown constraint'
    }
  }

  getNumberOfUsersConstraintDescription (params) {
    switch (params.relation) {
      case '<': return `Less than ${params.bound} user(s)`
      case '<=': return `Less than or equal to ${params.bound} user(s)`
      case '=': return `Equal to ${params.bound} user(s)`
      case '>=': return `Greater than or equal to ${params.bound} user(s)`
      case '>': return `Greater than ${params.bound} user(s)`
      default: return 'Unknown relation type'
    }
  }

  getClauseDescription () {
    switch (this.props.type) {
      case 0: return this.props.params.languages.join(', ')
      case 1: return this.props.params.licenses.join(', ')
      case 2: return this.getNumberOfUsersConstraintDescription(this.props.params)
      case 3: return (this.props.params.frequency === 1) ? 'every program run' : `every ${this.props.params.frequency} program runs`
      default: return 'Cannot display an unknown clause description'
    }
  }

  render () {
    return (
      <section>
        <header>
          <h4>{this.getClauseTitle()}</h4>
        </header>
        <p>{this.getClauseDescription()}</p>
      </section>
    )
  }
}
