import { Record } from 'immutable'

export const NewSpecialOfferClauseNullParams = Record({})

export const NewSpecialOfferClauseLanguageParams = Record({
  languages: []
})

export const NewSpecialOfferClauseLicenseParams = Record({
  licenses: []
})

export const NewSpecialOfferClauseUserParams = Record({
  relation: '>=',
  bound: 1
})

export const NewSpecialOfferClauseFrequencyParams = Record({
  frequency: 1
})

const NewSpecialOfferClause = Record({
  type: -1,
  params: new NewSpecialOfferClauseNullParams()
})

export default NewSpecialOfferClause
