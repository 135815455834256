import { Record } from 'immutable'
import AlteredReleaseFeatures from './altered-release-features'

const AlteredReleaseNote = Record({
  id: null,
  language: 'english',
  title: '',
  description: '',
  action: '',
  features: new AlteredReleaseFeatures()
})

export default AlteredReleaseNote
