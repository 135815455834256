import alt from '../utils/alt'
import AlteredSpecialOfferActions from '../actions/altered-special-offer'
import AlteredSpecialOffer from '../models/altered-special-offer'

class AlteredSpecialOfferStore {
  constructor () {
    this.state = {
      submitting: false,
      success: false,
      error: null,
      item: new AlteredSpecialOffer()
    }

    this.bindListeners({
      handleUpdate: AlteredSpecialOfferActions.UPDATE,
      handleSave: AlteredSpecialOfferActions.SAVE,
      handleSuccess: AlteredSpecialOfferActions.SUCCESS,
      handleFailure: AlteredSpecialOfferActions.FAILURE
    })
  }

  handleUpdate (release) {
    this.setState({
      submitting: false,
      success: false,
      error: null,
      item: release
    })
  }

  handleSave (release) {
    this.setState({
      submitting: true,
      success: false,
      error: null,
      item: release
    })
  }

  handleSuccess () {
    this.setState({
      submitting: false,
      success: true,
      error: null,
      item: new AlteredSpecialOffer()
    })
  }

  handleFailure (err) {
    this.setState({
      submitting: false,
      success: false,
      error: err,
      item: this.state.item
    })
  }
}

export default alt.createStore(AlteredSpecialOfferStore, 'AlteredSpecialOfferStore')
