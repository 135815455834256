import React from 'react'
import AlteredReleaseActions from '../../../actions/altered-release'
import AlteredReleaseStore from '../../../stores/altered-release'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'
import TextField from 'material-ui/TextField'

export default class EditFormAlteredNoteDeletedFeatureView extends React.Component {
  constructor () {
    super()
    this.onCancelDeletion = this.onCancelDeletion.bind(this)
  }

  onCancelDeletion (event) {
    const item = AlteredReleaseStore.getState().item
    const notes = item.getIn(['notes', 'update'])
    const ndx = notes.findIndex((note) => {
      return note.id === this.props.noteId
    })
    AlteredReleaseActions.update(item.setIn(['notes', 'update'], notes.update(ndx, (note) => {
      const features = note.getIn(['features', 'delete'])
      const ndx2 = features.findIndex((feature) => {
        return feature.id === this.props.id
      })
      const feature = features.get(ndx2)
      return note
        .setIn(['features', 'delete'], features.delete(ndx2))
        .setIn(['features', 'update'], note.getIn(['features', 'update']).push(feature))
    })))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release feature #${this.props.id}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Cancel deletion' primary onTouchTap={this.onCancelDeletion} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <TextField
              value={this.props.description}
              onChange={this.onChangeDescription}
              disabled
              hintText='Description'
              floatingLabelText='Description'
            />
          </CardText>
        </Card>
      </div>
    )
  }
}
