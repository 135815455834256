import alt from '../utils/alt'
import { List } from 'immutable'
import Language from '../models/language'
import apiFetch from '../utils/api-fetch'

function fetchLanguages () {
  return new Promise((resolve, reject) => {
    let response = null

    apiFetch('/api/languages/index')
      .then((_response) => {
        response = _response
        return _response.json()
      })
      .then((data) => {
        if (response.status === 200) {
          const languages = data.map((props) => {
            return new Language(props)
          })
          resolve(new List(languages))
        } else {
          throw new Error(data.error || 'Internal Server Error')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

class LanguageActions {
  update (languages) {
    return (dispatch) => {
      dispatch(languages)
    }
  }

  fetch () {
    return (dispatch) => {
      dispatch()
      fetchLanguages()
        .then((languages) => {
          this.update(languages)
        })
        .catch((err) => {
          this.failed(err)
        })
    }
  }

  failed (err) {
    return (dispatch) => {
      dispatch(err)
    }
  }
}

export default alt.createActions(LanguageActions)
