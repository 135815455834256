import React from 'react'
import NewSpecialOfferStore from '../../../stores/new-special-offer'
import NewSpecialOfferActions from '../../../actions/new-special-offer'
import CreateFormClausesView from './create-form-clauses-view'

import TextField from 'material-ui/TextField'
import DatePicker from 'material-ui/DatePicker'
import TimePicker from 'material-ui/TimePicker'

export default class CreateFormView extends React.Component {
  constructor () {
    super()
    this.state = NewSpecialOfferStore.getState()
    this.onUpdate = this.onUpdate.bind(this)
  }

  onUpdate (newSpecialOffer) {
    this.setState(newSpecialOffer)
  }

  componentDidMount () {
    NewSpecialOfferStore.listen(this.onUpdate)
  }

  componentWillUnmount () {
    NewSpecialOfferStore.unlisten(this.onUpdate)
  }

  onChangeTitle (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('title', event.target.value))
  }

  onChangeDescription (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('description', event.target.value))
  }

  onChangeAction (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('action', event.target.value))
  }

  onChangeExpiresOn (event, value) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('expiresOn', value))
  }

  onChangeExpiresAt (event, value) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('expiresAt', value))
  }

  onChangeLink (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('link', event.target.value))
  }

  onChangeCustomStylesheet (event) {
    const item = NewSpecialOfferStore.getState().item
    NewSpecialOfferActions.update(item.set('customStylesheet', event.target.value))
  }

  render () {
    const customDescriptionTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    const customStylesheetTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    const linkInputStyle = {
      minWidth: '40em'
    }

    return (
      <div>
        <TextField
          value={this.state.item.title}
          onChange={this.onChangeTitle}
          disabled={this.state.submitting}
          hintText='Title'
          floatingLabelText='Title'
        />
        <br />
        <TextField
          value={this.state.item.description}
          onChange={this.onChangeDescription}
          disabled={this.state.submitting}
          hintText='Description'
          floatingLabelText='Description'
          multiLine
          style={customDescriptionTextareaStyle}
        />
        <br />
        <TextField
          value={this.state.item.action}
          onChange={this.onChangeAction}
          disabled={this.state.submitting}
          hintText='Action'
          floatingLabelText='Action'
        />
        <br />
        <TextField
          value={this.state.item.customStylesheet}
          onChange={this.onChangeCustomStylesheet}
          disabled={this.state.submitting}
          hintText='Custom stylesheet'
          floatingLabelText='Custom stylesheet'
          multiLine
          style={customStylesheetTextareaStyle}
        />
        <br />
        <h3>Expires</h3>
        <DatePicker
          hintText='Expires on'
          floatingLabelText='Expires on'
          value={this.state.item.expiresOn}
          onChange={this.onChangeExpiresOn}
          disabled={this.state.submitting}
        />
        <TimePicker
          format='24hr'
          hintText='Expires at'
          floatingLabelText='Expires at'
          value={this.state.item.expiresAt}
          onChange={this.onChangeExpiresAt}
          disabled={this.state.submitting}
        />
        <br />

        <h3>Link</h3>
        <TextField
          type='url'
          value={this.state.item.link}
          onChange={this.onChangeLink}
          disabled={this.state.submitting}
          hintText='URL'
          floatingLabelText='URL'
          style={linkInputStyle}
        />

        <CreateFormClausesView languages={this.props.languages} clauses={this.state.item.clauses} disabled={this.state.submitting} />
        {
          (() => {
            if (this.state.submitting) {
              return <p>Submitting</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.error) {
              return <p>{this.state.error.message}</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.success) {
              return <p>Saved!</p>
            } else {
              return null
            }
          })()
        }
      </div>
    )
  }
}
