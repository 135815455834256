import React from 'react'
import NewReleaseStore from '../../../stores/new-release'
import NewReleaseActions from '../../../actions/new-release'
import NewReleaseNote from '../../../models/new-release-note'
import CreateFormNoteView from './create-form-note-view'

import { Toolbar, ToolbarTitle, ToolbarSeparator, ToolbarGroup } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'

export default class CreateFormNotesView extends React.Component {
  onAddNote (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('notes', item.get('notes').push(new NewReleaseNote())))
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text='Release notes' />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Add' primary onTouchTap={this.onAddNote} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            {this.props.notes.map((note, ndx) => {
              return <CreateFormNoteView key={ndx} id={ndx} languages={this.props.languages} language={note.language} title={note.title} description={note.description} action={note.action} features={note.features} disabled={this.props.disabled} />
            })}
            {
              (() => {
                if (this.props.notes.size === 0) {
                  return <p>No release notes yet</p>
                } else {
                  return null
                }
              })()
            }
          </CardText>
        </Card>
      </div>
    )
  }
}
