import React from 'react'
import LanguageStore from '../../../stores/language'
import LanguageActions from '../../../actions/language'

import SpecialOfferStore from '../../../stores/special-offer'
import SpecialOfferActions from '../../../actions/special-offer'

import SpecialOfferClauseStore from '../../../stores/special-offer-clause'
import SpecialOfferClauseActions from '../../../actions/special-offer-clause'

import TrackedLinkStore from '../../../stores/tracked-link'
import TrackedLinkActions from '../../../actions/tracked-link'

import EditFormView from './edit-form-view'
import { Card, CardTitle, CardText, CardActions } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'
import AlteredSpecialOfferStore from '../../../stores/altered-special-offer'
import AlteredSpecialOfferActions from '../../../actions/altered-special-offer'

import moment from 'moment'

export default class EditView extends React.Component {
  constructor () {
    super()
    this.state = {
      languages: LanguageStore.getState(),
      specialOffers: SpecialOfferStore.getState(),
      specialOfferClauses: SpecialOfferClauseStore.getState(),
      trackedLinks: TrackedLinkStore.getState()
    }

    this.onUpdateLanguages = this.onUpdateLanguages.bind(this)
    this.onUpdateSpecialOffers = this.onUpdateSpecialOffers.bind(this)
    this.onUpdateSpecialOfferClauses = this.onUpdateSpecialOfferClauses.bind(this)
    this.onUpdateTrackedLinks = this.onUpdateTrackedLinks.bind(this)
  }

  componentDidMount () {
    LanguageStore.listen(this.onUpdateLanguages)
    SpecialOfferStore.listen(this.onUpdateSpecialOffers)
    SpecialOfferClauseStore.listen(this.onUpdateSpecialOfferClauses)
    TrackedLinkStore.listen(this.onUpdateTrackedLinks)

    LanguageActions.fetch()
    SpecialOfferActions.fetch()
    SpecialOfferClauseActions.fetch()
    TrackedLinkActions.fetch()
  }

  componentWillUnmount () {
    LanguageStore.unlisten(this.onUpdateLanguages)
    SpecialOfferStore.unlisten(this.onUpdateSpecialOffers)
    SpecialOfferClauseStore.unlisten(this.onUpdateSpecialOfferClauses)
    TrackedLinkStore.unlisten(this.onUpdateTrackedLinks)
  }

  onUpdateLanguages (languages) {
    this.setState({
      languages: languages
    })
  }

  onUpdateSpecialOffers (specialOffers) {
    this.setState({
      specialOffers: specialOffers
    })
  }

  onUpdateSpecialOfferClauses (specialOfferClauses) {
    this.setState({
      specialOfferClauses: specialOfferClauses
    })
  }

  onUpdateTrackedLinks (trackedLinks) {
    this.setState({
      trackedLinks: trackedLinks
    })
  }

  isLoading () {
    return this.state.languages.loading || this.state.specialOffers.loading || this.state.specialOfferClauses.loading || this.state.trackedLinks.loading
  }

  isError () {
    return this.state.languages.err || this.state.specialOffers.err || this.state.specialOfferClauses.err || this.state.trackedLinks.err
  }

  getExpiresDate (dateTimeVal) {
    const dateVal = moment(dateTimeVal)
    return moment().set({
      year: dateVal.get('year'),
      month: dateVal.get('month'),
      date: dateVal.get('date')
    }).toDate()
  }

  getExpiresTime (dateTimeVal) {
    const timeVal = moment(dateTimeVal)
    return moment().set({
      hour: timeVal.get('hour'),
      minute: timeVal.get('minute'),
      second: timeVal.get('second'),
      millisecond: timeVal.get('millisecond')
    }).toDate()
  }

  getSpecialOfferData (specialOfferId) {
    if (this.isLoading()) {
      return null
    }

    const specialOffer = this.state.specialOffers.collection.find((specialOffer) => {
      return specialOffer.id === specialOfferId
    })

    const link = this.state.trackedLinks.collection.find((link) => {
      return link.id === specialOffer.linkId
    })

    const clauses = this.state.specialOfferClauses.collection.filter((specialOfferClause) => {
      return specialOfferClause.specialOfferId === specialOffer.id
    }).map((specialOfferClause) => {
      return {
        id: specialOfferClause.id,
        type: specialOfferClause.type,
        params: ((type, params) => {
          switch (type) {
            case 0: return {
              languages: params.languages.map((languageId) => {
                const language = this.state.languages.collection.find((language) => {
                  return language.id === languageId
                })

                return language.title
              })
            }
            case 1: return {
              licenses: params.licenses
            }
            case 2: return {
              bound: params.bound,
              relation: params.relation
            }
            case 3: return {
              frequency: params.frequency
            }
            default: return {
            }
          }
        })(specialOfferClause.type, specialOfferClause.params)
      }
    })

    return {
      id: specialOffer.id,
      title: specialOffer.title,
      description: specialOffer.description,
      link: link.target,
      action: specialOffer.action,
      customStylesheet: specialOffer.customStylesheet,
      created: specialOffer.created,
      expiresOn: (specialOffer.expires) ? this.getExpiresDate(specialOffer.expires) : null,
      expiresAt: (specialOffer.expires) ? this.getExpiresTime(specialOffer.expires) : null,
      clauses: clauses
    }
  }

  onSave () {
    const state = AlteredSpecialOfferStore.getState()
    AlteredSpecialOfferActions.save(state.item)
  }

  isSubmitting () {
    return AlteredSpecialOfferStore.getState().submitting
  }

  render () {
    return (
      <Card>
        <CardTitle title='Edit special offer' />
        <CardText>
          {
            (() => {
              if (this.isLoading()) {
                return <p>Loading</p>
              }
              if (this.isError()) {
                return <p>Failed to fetch special offers data</p>
              }
              return <EditFormView languages={this.state.languages.collection} specialOffer={this.getSpecialOfferData(parseInt(this.props.params.specialOfferId, 10))} />
            })()
          }
        </CardText>
        <CardActions>
          <RaisedButton label='Save' primary onTouchTap={this.onSave} disabled={this.isSubmitting()} />
        </CardActions>
      </Card>
    )
  }
}
