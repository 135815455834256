import React, { Component } from 'react'
import { Router, Route, IndexRoute, browserHistory, IndexRedirect } from 'react-router'
import Layout from './layout'
import HomeView from './home-view'
import DrExplainLayout from './drexplain/layout'
import DrExplainHomeView from './drexplain/home-view'
import DrExplainReleasesLayout from './drexplain/releases/layout'
import DrExplainReleasesIndexView from './drexplain/releases/index-view'
import DrExplainReleasesCreateView from './drexplain/releases/create-view'
import DrExplainReleasesEditView from './drexplain/releases/edit-view'
import DrExplainReleasesPreviewView from './drexplain/releases/preview-view'
import DrExplainSpecialOffersLayout from './drexplain/special-offers/layout'
import DrExplainSpecialOffersIndexView from './drexplain/special-offers/index-view'
import DrExplainSpecialOffersCreateView from './drexplain/special-offers/create-view'
import DrExplainSpecialOffersEditView from './drexplain/special-offers/edit-view'
import DrExplainSpecialOffersPreviewView from './drexplain/special-offers/preview-view'
import NotFoundView from './not-found-view'
import SigninView from './signin-view'
import SignoutView from './signout-view'
import IdentityStore from '../stores/identity'

export default class MainRouter extends Component {
  checkAuthentication (nextState, replace) {
    if (!IdentityStore.isAuthenticated()) {
      replace({
        pathname: '/signin',
        state: { nextPathname: nextState.location.pathname }
      })
    }
  }

  checkAuthenticationInverse (nextState, replace) {
    if (IdentityStore.isAuthenticated()) {
      replace({
        pathname: '/',
        state: { nextPathname: nextState.location.pathname }
      })
    }
  }

  render () {
    return (
      <Router history={browserHistory}>
        <Route path='/' component={Layout}>
          <IndexRoute component={HomeView} />
          <Route path='signin' component={SigninView} onEnter={this.checkAuthenticationInverse} />
          <Route path='signout' component={SignoutView} onEnter={this.checkAuthentication} />
          <Route path='drexplain' component={DrExplainLayout} onEnter={this.checkAuthentication}>
            <IndexRoute component={DrExplainHomeView} />
            <Route path='releases' component={DrExplainReleasesLayout}>
              <IndexRedirect to='/index' />
              <Route path='index' component={DrExplainReleasesIndexView} onEnter={this.checkAuthentication} />
              <Route path='new' component={DrExplainReleasesCreateView} onEnter={this.checkAuthentication} />
              <Route path=':releaseId/edit' component={DrExplainReleasesEditView} onEnter={this.checkAuthentication} />
              <Route path=':releaseId/preview' component={DrExplainReleasesPreviewView} onEnter={this.checkAuthentication} />
            </Route>
            <Route path='special-offers' component={DrExplainSpecialOffersLayout}>
              <IndexRedirect to='/index' />
              <Route path='index' component={DrExplainSpecialOffersIndexView} onEnter={this.checkAuthentication} />
              <Route path='new' component={DrExplainSpecialOffersCreateView} onEnter={this.checkAuthentication} />
              <Route path=':specialOfferId/edit' component={DrExplainSpecialOffersEditView} onEnter={this.checkAuthentication} />
              <Route path=':specialOfferId/preview' component={DrExplainSpecialOffersPreviewView} onEnter={this.checkAuthentication} />
            </Route>
          </Route>
          <Route path='*' component={NotFoundView} />
        </Route>
      </Router>
    )
  }
}
