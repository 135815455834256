import React from 'react'
import moment from 'moment'
import NoteItemView from './note-item-view'
import { Card, CardText } from 'material-ui/Card'
import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import TrackItemView from './track-item-view'

export default class ItemView extends React.Component {
  constructor () {
    super()
    this.onTouchTapEdit = this.onTouchTapEdit.bind(this)
    this.onTouchTapPreview = this.onTouchTapPreview.bind(this)
  }

  onTouchTapEdit () {
    this.context.router.push(`/drexplain/releases/${this.props.id}/edit`)
  }

  onTouchTapPreview () {
    this.context.router.push(`/drexplain/releases/${this.props.id}/preview`)
  }

  static get contextTypes () {
    return {
      router: React.PropTypes.object.isRequired,
      muiTheme: React.PropTypes.object.isRequired
    }
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release ${this.props.version.major}.${this.props.version.minor}.${this.props.version.patch}`} />
            <ToolbarSeparator />
            <div style={{ marginLeft: '2em' }}>
              <p style={{ margin: 0, lineHeight: `${this.context.muiTheme.toolbar.height}px` }}>{moment(this.props.created).format('lll')}</p>
            </div>
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Edit' primary onTouchTap={this.onTouchTapEdit} />
            <RaisedButton label='Preview' primary onTouchTap={this.onTouchTapPreview} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <TrackItemView linkSource={this.props.link.source} linkTarget={this.props.link.target} linkViews={this.props.linkStatistic.views} linkClicks={this.props.linkStatistic.clicks} activityBegin={this.props.created} activityEnd={this.props.previous ? this.props.previous.created : null} />
            {this.props.notes.map((note) => {
              return <NoteItemView key={note.id} id={note.id} language={note.language} title={note.title} description={note.description} action={note.action} features={note.features} />
            })}
          </CardText>
        </Card>
      </div>
    )
  }
}
