import React, { Component, PropTypes } from 'react'
import IdentityStore from '../stores/identity'
import AppBar from 'material-ui/AppBar'
import IconButton from 'material-ui/IconButton'
import FlatButton from 'material-ui/FlatButton'
import ActionHome from 'material-ui/svg-icons/action/home'
import Paper from 'material-ui/Paper'
import { grey800, grey400 } from 'material-ui/styles/colors'

export default class MainLayout extends Component {
  constructor () {
    super()
    this.state = IdentityStore.getState()
    this.onUpdateIdentity = this.onUpdateIdentity.bind(this)
    this.onTouchTapSignin = this.onTouchTapSignin.bind(this)
    this.onTouchTapSignout = this.onTouchTapSignout.bind(this)
    this.onTouchTapHome = this.onTouchTapHome.bind(this)
  }

  onUpdateIdentity (identity) {
    this.setState(identity)
  }

  onTouchTapSignin () {
    this.context.router.push('/signin')
  }

  onTouchTapSignout () {
    this.context.router.push('/signout')
  }

  onTouchTapHome () {
    this.context.router.push('/')
  }

  componentDidMount () {
    IdentityStore.listen(this.onUpdateIdentity)
  }

  componentWillUnmount () {
    IdentityStore.unlisten(this.onUpdateIdentity)
  }

  static get contextTypes () {
    return {
      router: PropTypes.object.isRequired
    }
  }

  render () {
    let footerStyle = {
      backgroundColor: grey800,
      color: grey400,
      position: 'absolute',
      bottom: 0,
      height: '50px',
      width: '100%',
      textAlign: 'center',
      fontSize: '0.9em'
    }

    return (
      <div>
        <header>
          <AppBar
            title={
              (() => {
                if (IdentityStore.isAuthenticated()) {
                  return `${IdentityStore.getState().token.username}@${window.location.hostname}`
                } else {
                  return window.location.hostname
                }
              })()
            }
            iconElementLeft={<IconButton onTouchTap={this.onTouchTapHome}><ActionHome /></IconButton>}
            iconElementRight={
              (() => {
                if (IdentityStore.isAuthenticated()) {
                  return <FlatButton label='Sign out' onTouchTap={this.onTouchTapSignout} />
                } else {
                  return <FlatButton label='Sign in' onTouchTap={this.onTouchTapSignin} />
                }
              })()
            }
          />
        </header>
        <main>
          {this.props.children}
        </main>
        <footer>
          <Paper zDepth={0} rounded={false} style={footerStyle}>
            <p>© {(new Date()).getFullYear()} Indigo Byte Systems, LLC</p>
          </Paper>
        </footer>
      </div>
    )
  }
}
