import React from 'react'
import NewReleaseStore from '../../../stores/new-release'
import NewReleaseActions from '../../../actions/new-release'
import CreateFormNotesView from './create-form-notes-view'
import TextField from 'material-ui/TextField'

export default class CreateFormView extends React.Component {
  constructor () {
    super()
    this.state = NewReleaseStore.getState()
    this.onUpdate = this.onUpdate.bind(this)
  }

  onUpdate (newRelease) {
    this.setState(newRelease)
  }

  componentDidMount () {
    NewReleaseStore.listen(this.onUpdate)
  }

  componentWillUnmount () {
    NewReleaseStore.unlisten(this.onUpdate)
  }

  onChangeVersionMajor (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.setIn(['version', 'major'], event.target.value))
  }

  onChangeVersionMinor (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.setIn(['version', 'minor'], event.target.value))
  }

  onChangeVersionPatch (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.setIn(['version', 'patch'], event.target.value))
  }

  onChangeLink (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('link', event.target.value))
  }

  onChangeCustomStylesheet (event) {
    const item = NewReleaseStore.getState().item
    NewReleaseActions.update(item.set('customStylesheet', event.target.value))
  }

  render () {
    const versionInputStyle = {
      width: '6em'
    }

    const linkInputStyle = {
      minWidth: '40em'
    }

    const customStylesheetTextareaStyle = {
      minWidth: '60em',
      minHeight: '10em',
      fontFamily: 'monospace'
    }

    return (
      <div>
        <h3>Version</h3>
        <TextField
          type='number'
          value={this.state.item.version.major}
          onChange={this.onChangeVersionMajor}
          disabled={this.state.submitting}
          hintText='Major'
          floatingLabelText='Major'
          style={versionInputStyle}
        />
        <br />
        <TextField
          type='number'
          value={this.state.item.version.minor}
          onChange={this.onChangeVersionMinor}
          disabled={this.state.submitting}
          hintText='Minor'
          floatingLabelText='Minor'
          style={versionInputStyle}
        />
        <br />
        <TextField
          type='number'
          value={this.state.item.version.patch}
          onChange={this.onChangeVersionPatch}
          disabled={this.state.submitting}
          hintText='Patch'
          floatingLabelText='Patch'
          style={versionInputStyle}
        />
        <br />
        <h3>Link</h3>
        <TextField
          type='url'
          value={this.state.item.link}
          onChange={this.onChangeLink}
          disabled={this.state.submitting}
          hintText='URL'
          floatingLabelText='URL'
          style={linkInputStyle}
        />
        <br />
        <h3>Extra styling</h3>
        <TextField
          value={this.state.item.customStylesheet}
          onChange={this.onChangeCustomStylesheet}
          disabled={this.state.submitting}
          hintText='Custom stylesheet'
          floatingLabelText='Custom stylesheet'
          multiLine
          style={customStylesheetTextareaStyle}
        />
        <br />
        <CreateFormNotesView languages={this.props.languages} notes={this.state.item.notes} disabled={this.state.submitting} />
        {
          (() => {
            if (this.state.submitting) {
              return <p>Submitting</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.error) {
              return <p>{this.state.error.message}</p>
            } else {
              return null
            }
          })()
        }
        {
          (() => {
            if (this.state.success) {
              return <p>Saved!</p>
            } else {
              return null
            }
          })()
        }
      </div>
    )
  }
}
