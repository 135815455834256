import alt from '../utils/alt'
import LanguageActions from '../actions/language'
import { List } from 'immutable'

class LanguageStore {
  constructor () {
    this.state = {
      loading: true,
      err: null,
      collection: new List()
    }

    this.bindListeners({
      handleUpdate: LanguageActions.UPDATE,
      handleFetch: LanguageActions.FETCH,
      handleFailed: LanguageActions.FAILED
    })
  }

  handleUpdate (languages) {
    this.setState({
      loading: false,
      err: null,
      collection: languages
    })
  }

  handleFetch () {
    this.setState({
      loading: true,
      err: null,
      collection: new List()
    })
  }

  handleFailed (err) {
    this.setState({
      loading: false,
      err: err,
      collection: new List()
    })
  }
}

export default alt.createStore(LanguageStore, 'LanguageStore')
