import { Component, PropTypes } from 'react'

export default class Layout extends Component {
  static get contextTypes () {
    return {
      router: PropTypes.object.isRequired
    }
  }

  render () {
    return this.props.children
  }
}
