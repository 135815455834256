import React from 'react'
import ItemView from './item-view'

export default class ListView extends React.Component {
  render () {
    return (
      <section>
        {this.props.releases.map((release, ndx, list) => {
          return <ItemView key={release.id} id={release.id} version={release.version} link={release.link} linkStatistic={release.linkStatistic} notes={release.notes} created={release.created} previous={(ndx === 0) ? null : list.get(ndx - 1)} />
        })}
      </section>
    )
  }
}
