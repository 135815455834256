import React from 'react'

import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'
import { Card, CardText } from 'material-ui/Card'
import TextField from 'material-ui/TextField'

export default class EditFormDeletedNoteFeatureView extends React.Component {
  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Release feature #${this.props.id}`} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <TextField
              value={this.props.description}
              disabled
              hintText='Description'
              floatingLabelText='Description'
            />
          </CardText>
        </Card>
      </div>
    )
  }
}
