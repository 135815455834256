import React from 'react'
import AlteredSpecialOfferActions from '../../../actions/altered-special-offer'
import AlteredSpecialOfferStore from '../../../stores/altered-special-offer'
import _ from 'lodash'

import { Toolbar, ToolbarGroup, ToolbarTitle, ToolbarSeparator } from 'material-ui/Toolbar'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardText } from 'material-ui/Card'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'
import TextField from 'material-ui/TextField'

export default class EditFormDeletedClauseView extends React.Component {
  constructor () {
    super()
    this.onCancelDeletion = this.onCancelDeletion.bind(this)
  }

  onCancelDeletion (event) {
    const item = AlteredSpecialOfferStore.getState().item
    const clauses = item.getIn(['clauses', 'delete'])
    const ndx = clauses.findIndex((clause) => {
      return clause.id === this.props.id
    })
    const clause = clauses.get(ndx)
    AlteredSpecialOfferActions.update(item
      .setIn(['clauses', 'delete'], clauses.delete(ndx))
      .setIn(['clauses', 'update'], item.getIn(['clauses', 'update']).push(clause))
    )
  }

  render () {
    return (
      <div>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={`Special offer clause #${this.props.id}`} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarSeparator />
            <RaisedButton label='Cancel deletion' primary onTouchTap={this.onCancelDeletion} disabled={this.props.disabled} />
          </ToolbarGroup>
        </Toolbar>
        <Card>
          <CardText>
            <SelectField
              hintText='Type'
              floatingLabelText='Type'
              value={this.props.type}
              disabled
            >
              <MenuItem key={1} value={0} primaryText='Language constraint' />
              <MenuItem key={2} value={1} primaryText='License constraint' />
              <MenuItem key={3} value={2} primaryText='Users constraint' />
              <MenuItem key={4} value={3} primaryText='Frequency constraint' />
            </SelectField>
              {
                (() => {
                  if (this.props.type === 0) {
                    return (
                      <div>
                      {this.props.languages.map((language, ndx) => {
                        return <Checkbox key={ndx} label={language.title} value={language.title} checked={_.includes(this.props.params.languages, language.title)} disabled />
                      })}
                      </div>
                    )
                  } else if (this.props.type === 1) {
                    return (
                      <div>
                        <Checkbox label='Trial' value={'0'} checked={_.includes(this.props.params.licenses, 0)} disabled />
                        <Checkbox label='Regular' value={'2'} checked={_.includes(this.props.params.licenses, 2)} disabled />
                        <Checkbox label='Advanced' value={'3'} checked={_.includes(this.props.params.licenses, 3)} disabled />
                        <Checkbox label='Ultima' value={'7'} checked={_.includes(this.props.params.licenses, 7)} disabled />
                        <Checkbox label='Home' value={'8'} checked={_.includes(this.props.params.licenses, 8)} disabled />
                        <Checkbox label='Educational' value={'16'} checked={_.includes(this.props.params.licenses, 16)} disabled />
                      </div>
                    )
                  } else if (this.props.type === 2) {
                    return (
                      <div>
                        <SelectField
                          hintText='Relation'
                          floatingLabelText='Relation'
                          value={this.props.params.relation}
                          disabled
                        >
                          <MenuItem key={0} value='<' primaryText='Less than' />
                          <MenuItem key={1} value='<=' primaryText='Less than or equal to' />
                          <MenuItem key={2} value='=' primaryText='Equal to' />
                          <MenuItem key={3} value='>=' primaryText='Equal to or greater than' />
                          <MenuItem key={4} value='>' primaryText='Greater than' />
                        </SelectField>
                        <br />
                        <TextField
                          type='number'
                          value={this.props.params.bound}
                          disabled
                          hintText='Bound'
                          floatingLabelText='Bound'
                        />
                      </div>
                    )
                  } else if (this.props.type === 3) {
                    return (
                      <div>
                        <TextField
                          type='number'
                          value={this.props.params.frequency}
                          disabled
                          hintText='Frequency'
                          floatingLabelText='Frequency'
                        />
                      </div>
                    )
                  }
                })()
              }
          </CardText>
        </Card>
      </div>
    )
  }
}
